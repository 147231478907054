import { PlusOutlined } from '@ant-design/icons';
import { Col, Form, message, Modal, Row, Typography } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { CategorySelectionInput } from 'components/UI/FormItems/CategorySelectionInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCreateReleasedProductMutation, useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { setSelectedReleasedEntity } from 'redux/slices/createProductMasterStateSlice';
import { RootState } from 'redux/store';

interface Props {
  releasedProductCombinations: string[];
}

interface CategoryAssignments {
  displayOrder: number;
  productCategoryHierarchyName: string;
  productCategoryName: string;
  productNumber: string;
}

export interface CreateReleasedProductPayload {
  productNumber: string;
  reservationHierarchy: string;
  itemModelGroup: string;
  productDimensionGroupName?: string;
  productGroupId: string;
  unitOfMeasure: string;
  legalEntity?: string;
}

export const ReleaseProductMasterForm: React.FC<Props> = ({ releasedProductCombinations }: Props) => {
  const [legalEntity, setLegalEntity] = useState('');
  const [showModal, setShowModal] = useState(false);
  const { maggieCadabbyParams } = useSelector((state: RootState) => state);
  const { productMasterId } = useParams();
  const { data: productMasterData } = useGetProductMasterQuery(productMasterId as string, { skip: !productMasterId });
  const [createReleasedProduct] = useCreateReleasedProductMutation();
  const dispatch = useDispatch();

  const categoryAssignment = productMasterData?.productCategoryAssignments.find((ele: CategoryAssignments) => ele.productCategoryName);

  const { data } = useGetLegalEntitiesQuery(maggieCadabbyParams);

  const formik = useFormik<CreateReleasedProductPayload>({
    enableReinitialize: true,
    validationSchema: undefined,
    initialValues: {
      productNumber: productMasterId ?? '',
      reservationHierarchy: productMasterData?.trackingDimensionGroupName ?? '',
      itemModelGroup: 'STOCK',
      productDimensionGroupName: 'OCD',
      productGroupId: categoryAssignment ? (categoryAssignment as CategoryAssignments).productCategoryName : '',
      unitOfMeasure: 'EA'
    },
    onSubmit: async (values) => {
      try {
        const response = await createReleasedProduct({ dataAreaId: legalEntity, payload: values });

        if ((response as { error: any }).error) {
          message.error((response as { error: { data: string } }).error.data);
        } else {
          setShowModal(false);
          dispatch(setSelectedReleasedEntity(legalEntity));
          message.success(`Product was successfully released!`);
        }
      } catch (error) {
        console.log(error);
        message.error((error as { error: { data: string } }).error.data);
      }
    }
  });

  const handleValueChange = (value: string): void => {
    setLegalEntity(value);
  };

  const handleCancel = (): void => {
    if (formik.isSubmitting) return;
    setShowModal(false);
    setLegalEntity('');
    formik.resetForm();
  };

  const legalEntities = data?.data
    .filter((legalEntity) => !releasedProductCombinations.includes(legalEntity.dataAreaId.toLowerCase()))
    .map((legalEntity) => ({ label: legalEntity.dataAreaId, value: legalEntity.dataAreaId }));

  return (
    <FormikProvider value={formik}>
      <Row align="middle" onClick={(): void => setShowModal(true)}>
        <Typography.Text>
          <PlusOutlined style={{ marginRight: 4 }} />
          Release to Legal Entity
        </Typography.Text>
      </Row>
      <Modal
        okButtonProps={{ disabled: !formik.values.legalEntity, loading: formik.isSubmitting }}
        closable={!formik.isSubmitting}
        cancelButtonProps={{ disabled: formik.isSubmitting }}
        onOk={(): Promise<void> => formik.submitForm()}
        okText="Release"
        title={<AntPageTitle text="Release Product Master" />}
        open={showModal}
        onCancel={handleCancel}>
        <Form style={{ marginBottom: 16 }} layout="vertical">
          <Col style={{ marginBottom: 8 }}>
            <SelectInput
              options={data?.data.map((ent) => ({ label: ent.dataAreaId, value: ent.dataAreaId }))}
              label="Legal Entity"
              fieldName="legalEntity"
              onChange={(value: string): void => handleValueChange(value)}
            />
          </Col>
          <Col style={{ marginBottom: 8 }}>
            <SelectInput fieldName="itemModelGroup" label="Item Model Group" options={[{ label: 'Stock', value: 'STOCK' }]} defaultValue={'STOCK'} />
          </Col>
          <Col style={{ marginBottom: 8 }}>
            <SelectInput fieldName="unitOfMeasure" label="Unit of Measure" options={[{ label: 'EA', value: 'EA' }]} defaultValue={'EA'} />
          </Col>
          <Col style={{ marginBottom: 8 }}>
            {categoryAssignment ? (
              <SelectInput
                fieldName="productGroupId"
                label="Category"
                options={[
                  {
                    label: categoryAssignment && (categoryAssignment as CategoryAssignments).productCategoryName,
                    value: categoryAssignment && (categoryAssignment as CategoryAssignments).productCategoryName
                  }
                ]}
                defaultValue={categoryAssignment && (categoryAssignment as CategoryAssignments).productCategoryName}
              />
            ) : (
              <CategorySelectionInput fieldName="productGroupId" label="Category" />
            )}
          </Col>
          <Col style={{ marginBottom: 8 }}>
            <SelectInput fieldName="productDimensionGroupName" label="Product Dimension Group" options={[{ label: 'OCD', value: 'OCD' }]} defaultValue={'OCD'} />
          </Col>
        </Form>
      </Modal>
    </FormikProvider>
  );
};
