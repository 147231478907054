import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  ownerIds: string[];
  conditionIds: string[];
  dispositionIds: string[];
}

const initialState: InitialState = {
  ownerIds: [],
  conditionIds: [],
  dispositionIds: []
};

export const stagedOcdSlice = createSlice({
  name: 'stagedOcd',
  initialState,
  reducers: {
    setOwnerIds: (state, { payload }: PayloadAction<string[]>) => {
      state.ownerIds = payload;
    },
    setConditionIds: (state, { payload }: PayloadAction<string[]>) => {
      state.conditionIds = payload;
    },
    setDispositionIds: (state, { payload }: PayloadAction<string[]>) => {
      state.dispositionIds = payload;
    },
    clearAllOcd: (state) => {
      state.ownerIds = [];
      state.conditionIds = [];
      state.dispositionIds = [];
    }
  }
});

export const { setOwnerIds, setConditionIds, setDispositionIds, clearAllOcd } = stagedOcdSlice.actions;
