import { Card, Col, Divider, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { EditConditionTags } from './EditConditionTags';
import { EditDispositionTags } from './EditDispositionTags';
import { EditOwnerTags } from './EditOwnerTags';

export const EditDimensionsStep: React.FC = () => {
  const { productMasterId } = useParams();
  const { data: productMasterData } = useGetProductMasterQuery(productMasterId as string, { skip: !productMasterId });

  return (
    <Col>
      <Col style={{ padding: 10, background: '#FFEAC1' }}>
        <Row justify="space-between" align="middle" style={{ margin: 2 }}>
          <AntPageTitle text="Product Master Dimensions" />
        </Row>
      </Col>
      <Card>
        <Row gutter={[32, 16]}>
          <Col span={24}>
            <EditOwnerTags productMasterData={productMasterData} label="Owner" />
          </Col>
          <Divider />
          <Col span={24}>
            <EditConditionTags productMasterData={productMasterData} label="Condition" />
          </Col>
          <Divider />
          <Col span={24} style={{ marginBottom: 24 }}>
            <EditDispositionTags productMasterData={productMasterData} label="Disposition" />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
