import { Card, Col, Row, Typography } from 'antd';
import { MultipleConditionSelect } from 'components/atoms/MultipleConditionSelect';
import { MultipleDispositionSelect } from 'components/atoms/MultipleDispositionSelect';
import { MultipleOwnerSelect } from 'components/atoms/MultipleOwnerSelect';
import { SelectedVariantsTable } from 'components/atoms/SelectedVariantsTable';
import { toRgb, userFormColors } from 'components/common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { CreateProductMastersModel } from 'models/ProductMaster';
import React from 'react';
import { useGetRolesQuery } from 'redux/services/cookieMonster/cookieMonsterApi';

const styles: InlineStylesModel = {
  mainContainer: {
    overflowY: 'auto',
    overflowX: 'hidden',
    margin: 0,
    width: '100%'
    // height: 'calc(100vh - 112px - 46px - 50px - 110px - 25px )'
  },
  cardsContainer: {
    padding: '0px',
    margin: '0px'
  },

  infoTitle: {
    fontSize: '16px',
    fontWeight: 600
  },
  infoText: {
    fontSize: '15px',
    minHeight: '27px'
  },
  cardHead: {
    fontSize: '18px',
    fontWeight: 600
  },
  cardBody: {
    padding: '25px 20px 20px 25px'
  },
  headerCard: {
    backgroundColor: '#FFEAC1'
  },
  deliveryCard: {
    backgroundColor: '#CED5F2'
  },
  productsTableCard: {
    backgroundColor: '#b0b2bc'
  },
  releasedProductHeader: {
    background: '#18a79984'
  },
  tableBody: {
    padding: 0
  },
  extra: {
    fontSize: '15px',
    fontWeight: 500
  },
  addressLine: {
    marginBottom: 0
  },
  attention: {
    maxWidth: '300px'
  },
  red: {
    color: toRgb(userFormColors.redSalsa)
  },
  wrapper: {
    margin: 5
  },
  container: {
    padding: 15
  },
  title: {
    fontSize: '0.85em',
    // whiteSpace: 'nowrap',
    // ParagraphOverflow: 'ellipsis',
    color: 'black',
    textTransform: 'capitalize',
    marginBottom: 0
  },
  titleContainer: {
    paddingLeft: 10
  },
  iconContainer: {
    paddingLeft: 10
  },
  editButton: {
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 7
  },
  subTitle: {
    fontSize: '0.75em',
    marginBottom: 0
  }
};

export const OverviewStep: React.FC = () => {
  const {
    values: {
      productNumber,
      productName,
      productDescription,
      storageDimensionGroupName,
      configurationTechnology,
      productDimensionGroupName,
      trackingDimensionGroupName,
      manufacturerId,
      categoryId,
      unitOfMeasure,
      itemModelGroup,
      reservationHierarchy,
      legalEntity,
      productOwners,
      productConditions,
      productDispositions,
      productVariants
    }
  } = useFormikContext<CreateProductMastersModel>();

  const { data: roles } = useGetRolesQuery({ includePermissions: false });

  // const assignedRoles = roles?.data.filter((role) => roleIdsToAssign.includes(role.id));

  const headerInfoCardJSX = (
    <Card style={{ height: '100%', width: '100%' }} size="small" title="Product Details" bordered={false} headStyle={{ ...styles.cardHead, ...styles.headerCard }} bodyStyle={styles.cardBody}>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Product Number:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{productNumber || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Product Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{productName || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Product Description:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{productDescription || 'N/A'}</Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Storage Dimension Group Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{storageDimensionGroupName || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Configuration Technology:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{configurationTechnology || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Product Dimension Group Name:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{productDimensionGroupName || 'N/A'}</Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );

  const releasedProductInfo = (
    <Card size="small" title="Released Product" bordered={false} headStyle={{ ...styles.cardHead, ...styles.releasedProductHeader }} bodyStyle={styles.cardBody}>
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Legal Entity:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{legalEntity || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Unit of Measure:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{unitOfMeasure || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Product Dimension Group:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{productDimensionGroupName || 'N/A'}</Typography.Paragraph>
        </Col>
        <Col span={12}>
          <Typography.Title level={5} style={styles.infoTitle}>
            Item Model Group:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{itemModelGroup || 'N/A'}</Typography.Paragraph>
          <Typography.Title level={5} style={styles.infoTitle}>
            Category:
          </Typography.Title>
          <Typography.Paragraph style={styles.infoText}>{categoryId || 'N/A'}</Typography.Paragraph>
        </Col>
      </Row>
    </Card>
  );

  /* ****************** Render ****************** */
  return (
    <Row style={styles.mainContainer} gutter={[0, 16]}>
      <Col span={24} style={styles.cardsContainer}>
        <Row gutter={[16, 16]}>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            {headerInfoCardJSX}
          </Col>
          <Col lg={24} xl={12} style={{ width: '100%' }}>
            <Card style={{ height: '100%', width: '100%' }} size="small" title="Dimensions" bordered={false} headStyle={{ ...styles.cardHead, ...styles.deliveryCard }} bodyStyle={styles.cardBody}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <MultipleOwnerSelect fieldName="productOwners" label="Owner" />
                </Col>
                <Col span={24}>
                  <MultipleConditionSelect fieldName="productConditions" label="Condition" />
                </Col>
                <Col span={24}>
                  <MultipleDispositionSelect fieldName="productDispositions" label="Disposition" />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Col>
      <Row gutter={[16, 16]}>
        <Col lg={24} xl={12} style={{ width: '100%' }}>
          <Card size="small" title="Staged Variants" headStyle={{ ...styles.cardHead, ...styles.productsTableCard }}>
            <SelectedVariantsTable />
          </Card>
        </Col>
        <Col lg={24} xl={12} style={{ width: '100%' }}>
          {releasedProductInfo}
        </Col>
      </Row>
    </Row>
  );
};
