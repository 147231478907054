import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { AnimalsCondition } from 'models/AnimalsCondition';
import { AnimalsDisposition } from 'models/AnimalsDisposition';
import { AnimalsOwner } from 'models/AnimalsOwner';
import { ApiMultipleResponse } from 'models/ApiModels';
import { RootState } from 'redux/store';

export interface CreateDimensionPayload {
  name: string;
  description: string;
}

const baseUrl = process.env.REACT_APP_ANIMAL_BASE_URL;
const functionsKey = process.env.REACT_APP_API_HOST_KEY_ANIMAL;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).app.accessToken;

    if (token && functionsKey) {
      headers.set('authorization', `Bearer ${token}`);
      headers.set('x-functions-key', functionsKey);
      headers.set('Content-Type', 'application/json');
    }

    return headers;
  }
});

export const animalsApi = createApi({
  reducerPath: 'animalsApi',
  baseQuery,
  tagTypes: ['Owners', 'Conditions', 'Dispositions'],
  endpoints: (builder) => ({
    getOwners: builder.query<ApiMultipleResponse<AnimalsOwner>, void>({
      query: () => ({
        url: '/owners?overrideSkipTake=true'
      }),
      providesTags: ['Owners']
    }),
    getConditions: builder.query<ApiMultipleResponse<AnimalsCondition>, void>({
      query: () => ({
        url: '/conditions?overrideSkipTake=true'
      }),
      providesTags: ['Conditions']
    }),
    getDispositions: builder.query<ApiMultipleResponse<AnimalsDisposition>, void>({
      query: () => ({
        url: '/dispositions?overrideSkipTake=true'
      }),
      providesTags: ['Dispositions']
    }),
    createOwner: builder.mutation<CreateDimensionPayload, { payload: CreateDimensionPayload }>({
      query: ({ payload }) => ({
        url: `/owners`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Owners']
    }),
    createCondition: builder.mutation<CreateDimensionPayload, { payload: CreateDimensionPayload }>({
      query: ({ payload }) => ({
        url: `/conditions`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Conditions']
    }),
    createDisposition: builder.mutation<CreateDimensionPayload, { payload: CreateDimensionPayload }>({
      query: ({ payload }) => ({
        url: `/dispositions`,
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['Dispositions']
    }),
    getVersion: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/version',
        responseHandler: (response): Promise<string> => response.text()
      })
    }),
    getApiName: builder.query<string, void>({
      query: () => ({
        url: '/diagnostics/apiName',
        responseHandler: (response): Promise<string> => response.text()
      })
    })
  })
});

export const {
  useGetOwnersQuery,
  useLazyGetOwnersQuery,
  useLazyGetConditionsQuery,
  useLazyGetDispositionsQuery,
  useGetConditionsQuery,
  useGetDispositionsQuery,
  useCreateOwnerMutation,
  useCreateConditionMutation,
  useCreateDispositionMutation,
  useGetVersionQuery,
  useGetApiNameQuery
} = animalsApi;
