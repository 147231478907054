import { Menu, Row, Space } from 'antd';
import { ProductMasterFormMenuKeys } from 'common/enums/MenuKeys';
import { LottieIcon } from 'components/atoms/LottieIcon';
import { toRgb, userFormColors } from 'components/common/styles/colors';
import { useFormikContext } from 'formik';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { CreateProductMastersModel } from 'models/ProductMaster';
import React, { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deliveryLottie, headerInfoLottie, productsLottie, reviewOrderLottie, serviceLottie } from '../../../src/assets/customer-lottie';

const styles: InlineStylesModel = {
  valid: {
    color: toRgb(userFormColors.illuminatingEmerald)
  }
};

export const ProductMasterFormNavigation: React.FC = () => {
  const { values, errors, touched, setTouched } = useFormikContext<CreateProductMastersModel>();
  const [visited, setVisited] = useState<string[]>(['product-details']);
  const { pathname } = useLocation();
  const { productMasterId } = useParams();
  const navigate = useNavigate();

  const validProductDetailsStep = Boolean(values.productNumber && values.productName && values.productDescription && values.trackingDimensionGroupName && values.manufacturerId);
  const validOCDStep = Boolean(values.productOwners.length && values.productConditions.length && values.productDispositions.length);
  const validVariantStep = Boolean(values.productVariants.length);
  const validReleasedProductStep = Boolean(values.legalEntity && values.categoryId);

  const productDetailsHasErrors = Boolean(
    (errors.productNumber && touched.productNumber) ||
      (errors.productName && touched.productName) ||
      (errors.productDescription && touched.productDescription) ||
      (errors.trackingDimensionGroupName && touched.trackingDimensionGroupName) ||
      (errors.manufacturerId && touched.manufacturerId)
  );

  const oCDHasErrors = Boolean(
    (!values.productOwners.length && touched.productOwners) || (!values.productConditions.length && touched.productConditions) || (!values.productDispositions.length && touched.productDispositions)
  );

  const variantHasErrors = Boolean(!values.productVariants.length && touched.productVariants);

  const releasedProductHasErrors = Boolean(errors.legalEntity && touched.legalEntity) || (errors.categoryId && touched.categoryId);

  const isCreate = pathname.includes('/create-product-master');
  const isView = pathname.includes('/edit-product-master');

  const handleNavigation = (info: { key: string }): void => {
    setVisited((prev) => (!prev.includes(info.key) ? [...prev, info.key] : [...prev]));
    if (isCreate) {
      if (info.key === ProductMasterFormMenuKeys.ProductDetails) {
        navigate('/create-product-master/product-details');
      } else if (info.key === ProductMasterFormMenuKeys.Dimensions) {
        navigate('/create-product-master/dimensions');
      } else if (info.key === ProductMasterFormMenuKeys.Variants) {
        navigate(`/create-product-master/variants`);
      } else if (info.key === ProductMasterFormMenuKeys.ReleaseProduct) {
        navigate(`/create-product-master/release-product`);
      } else {
        navigate(`/create-product-master/overview`);
      }
    } else {
      if (info.key === ProductMasterFormMenuKeys.ProductDetails) {
        navigate(`/edit-product-master/${productMasterId}/product-details`);
      } else if (info.key === ProductMasterFormMenuKeys.Dimensions) {
        navigate(`/edit-product-master/${productMasterId}/dimensions`);
      } else if (info.key === ProductMasterFormMenuKeys.Variants) {
        navigate(`/edit-product-master/${productMasterId}/variants`);
      } else if (info.key === ProductMasterFormMenuKeys.ReleaseProduct) {
        navigate(`/edit-product-master/${productMasterId}/release-product`);
      } else {
        navigate(`/edit-product-master/${productMasterId}/overview`);
      }
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Row style={{ width: '100%', background: 'white' }} justify="center">
        <Menu disabledOverflow={true} defaultSelectedKeys={[ProductMasterFormMenuKeys.ProductDetails]} onClick={handleNavigation} mode="horizontal" style={{ justifyContent: 'center' }}>
          <Menu.Item key={ProductMasterFormMenuKeys.ProductDetails} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <LottieIcon required lottieFile={headerInfoLottie} complete={Boolean(validProductDetailsStep)} title="Product Details" />
          </Menu.Item>
          <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={ProductMasterFormMenuKeys.Dimensions}>
            <LottieIcon complete={validOCDStep} lottieFile={serviceLottie} required title="Dimensions" />
          </Menu.Item>
          <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={ProductMasterFormMenuKeys.Variants}>
            <LottieIcon lottieFile={productsLottie} complete={validVariantStep} required title="Product Variants" />
          </Menu.Item>
          <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={ProductMasterFormMenuKeys.ReleaseProduct}>
            <LottieIcon required title="Released Product" lottieFile={deliveryLottie} complete={validReleasedProductStep} />
          </Menu.Item>
          {!isView && (
            <Menu.Item style={{ paddingLeft: 10, paddingRight: 10 }} key={ProductMasterFormMenuKeys.Overview}>
              <LottieIcon complete={false} title="Overview" lottieFile={reviewOrderLottie} />
            </Menu.Item>
          )}
        </Menu>
      </Row>
    </Space>
  );
};
