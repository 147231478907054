import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Button, Card, Col, List, message, Modal, Row, Typography } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { FormikProvider, useFormik } from 'formik';
import { CardPropsModel } from 'models/CardPropsModel';
import { InlineStylesModel } from 'models/InlineStylesModel copy';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  useCreateProductMasterVariantMutation,
  useCreateReleasedProductVariantMutation,
  useGetProductMasterQuery,
  useGetReleasedProductsQuery,
  useLazyGetCustomReleasedProductQuery
} from 'redux/services/donaldGrump/donaldGrumpApi';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { RootState } from 'redux/store';
import { ReleaseProductMasterForm } from './ReleaseProductMasterForm';
import { ReleaseVariant } from './ReleaseVariant';

export interface ReleaseProductModel {
  variants: CardPropsModel[];
}

const styles: InlineStylesModel = {
  smallGrouping: {
    color: 'grey',
    fontSize: '12px',
    background: '#8383831a',
    padding: '5px 15px',
    borderRadius: 100
  }
};

export const ViewReleaseProductStep: React.FC = () => {
  const [releaseProductVariant] = useCreateReleasedProductVariantMutation();
  const [createReleasedProductVariant] = useCreateProductMasterVariantMutation();
  const [dataAreaId, setDataAreaId] = useState('');
  const { productMasterId } = useParams();
  const { data: productMasterData } = useGetProductMasterQuery(productMasterId ?? skipToken);

  const formik = useFormik<ReleaseProductModel>({
    enableReinitialize: true,
    validationSchema: undefined,
    initialValues: {
      variants: []
    },
    onSubmit: async (values) => {
      try {
        for (const variant of values.variants) {
          const productDescription = productMasterData?.productDescription;

          await createReleasedProductVariant({
            productId: productMasterId as string,
            payload: { ...variant, ...{ productDescription, productName: productMasterId } }
          });
        }
        for (const variant of values.variants) {
          await releaseProductVariant({ dataAreaId, productId: productMasterId as string, payload: variant });
        }

        message.success(`Product Variants were successfully created!`);
      } catch (error) {
        console.log(error);
      }
    }
  });

  const { maggieCadabbyParams } = useSelector((state: RootState) => state);
  const [showModal, setShowModal] = useState<boolean>();
  const [showReleaseVariantModal, setShowReleaseVariantModal] = useState(false);
  const { data: releasedProducts } = useGetReleasedProductsQuery({ productNumber: productMasterId, offset: 0 }, { skip: !productMasterId });

  const [trigger, { data: releasedProductData, isFetching: fetchingReleasedProductData }] = useLazyGetCustomReleasedProductQuery();
  const { data, isFetching: fetchingLegalEntities } = useGetLegalEntitiesQuery(maggieCadabbyParams);

  const legalEntities = data?.data.map((legalEntity) => ({ name: legalEntity.name, description: legalEntity.description, dataAreaId: legalEntity.dataAreaId }));
  const releasedProductCombinations = releasedProducts?.data.map((releasedProduct) => releasedProduct.dataAreaId.toLowerCase());

  const handleLegalEntitySelect = (dataAreaId: string): void => {
    setShowModal(true);
    setDataAreaId(dataAreaId);
    if (productMasterId) {
      trigger({ productId: productMasterId, dataAreaId });
    }
  };

  const handleReleaseMoreVariants = (): void => {
    setShowModal(false);
    setShowReleaseVariantModal(true);
  };

  return (
    <>
      <Col style={{ padding: 10, background: 'rgb(206, 213, 242)', marginTop: 8 }}>
        <ReleaseProductMasterForm releasedProductCombinations={releasedProductCombinations ?? []} />
      </Col>
      <Card>
        <List
          dataSource={legalEntities?.filter((legalEntity) => releasedProductCombinations?.includes(legalEntity.dataAreaId.toLowerCase()))}
          grid={{ gutter: 8, xs: 1, sm: 2, md: 3, lg: 3, xl: 3, xxl: 3 }}
          loading={{ indicator: <BlockLoader direction="loader loader--slideUp" />, spinning: fetchingLegalEntities }}
          renderItem={(item): JSX.Element => (
            <Col style={{ marginBottom: 8 }}>
              <Card hoverable bodyStyle={{ padding: 10 }} onClick={(): void => handleLegalEntitySelect(item.dataAreaId)}>
                <Col>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {item.name}
                  </Typography.Title>
                </Col>
                <Col style={{ marginBottom: 5 }}>
                  <Typography.Text style={{ fontSize: '12px' }}>{item.description}</Typography.Text>
                </Col>
                <Row justify="end">
                  <Typography.Text style={styles.smallGrouping}>{item.dataAreaId}</Typography.Text>
                </Row>
              </Card>
            </Col>
          )}
        />
      </Card>
      <Modal title={<AntPageTitle text="Existing Product Variants" />} footer={false} visible={showModal} onCancel={(): void => setShowModal(false)} width={'50vw'}>
        <List
          loading={{ indicator: <BlockLoader direction="loader loader--slideUp" />, spinning: fetchingReleasedProductData }}
          style={{ maxHeight: 600, overflow: 'scroll' }}
          dataSource={releasedProductData?.releasedProductVariants}
          grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 2, xxl: 2 }}
          renderItem={(item): JSX.Element => (
            <Col style={{ marginBottom: 8 }}>
              <Card bodyStyle={{ padding: 10 }}>
                <Col>
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {item.ownerId} :: {item.conditionId} :: {item.dispositionId}
                  </Typography.Title>
                </Col>
                <Col style={{ marginBottom: 5 }}>
                  <Typography.Text style={{ fontSize: '12px' }}>{item.description}</Typography.Text>
                </Col>
                <Row justify="end">
                  <Typography.Text style={styles.smallGrouping}>{item.dataAreaId}</Typography.Text>
                </Row>
              </Card>
            </Col>
          )}
        />
        <Row justify="center" style={{ marginTop: 8 }}>
          <Button onClick={(): void => handleReleaseMoreVariants()}>Release More Variants</Button>
        </Row>
      </Modal>
      <FormikProvider value={formik}>
        <Modal
          okText="Create and Release"
          onOk={(): Promise<void> => formik.submitForm()}
          title={<AntPageTitle text="Release Product Master Variant" />}
          width="50vw"
          visible={showReleaseVariantModal}
          onCancel={(): void => setShowReleaseVariantModal(false)}>
          <ReleaseVariant releasedProductData={releasedProductData} />
        </Modal>
      </FormikProvider>
    </>
  );
};
