import { Typography } from 'antd';
import React from 'react';
import '../../common/styles/loaderStyle.less';

interface DirectionProps {
  direction: string;
  style?: React.CSSProperties;
  loadingMessage?: string;
  fullPageLoader?: boolean;
}

export const BlockLoader = ({ direction, style, loadingMessage, fullPageLoader }: DirectionProps): JSX.Element => {
  if (fullPageLoader) {
    return (
      <div
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          background: '#ffffffad',
          top: 0,
          left: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <div className={direction} style={loadingMessage ? { marginBottom: 0, height: 100, marginTop: 60 } : { ...style } ?? undefined}>
          <span className="loader-item">1</span>
          <span className="loader-item">2</span>
          <span className="loader-item">3</span>
          <span className="loader-item">4</span>
          <span className="loader-item">5</span>
          <span className="loader-item">6</span>
        </div>
        <Typography.Text style={{ fontSize: 20, marginTop: 0 }}>{loadingMessage}</Typography.Text>
      </div>
    );
  }

  return (
    <>
      <div className={direction} style={loadingMessage ? { marginBottom: 0, height: 100, marginTop: 60 } : { ...style } ?? undefined}>
        <span className="loader-item">1</span>
        <span className="loader-item">2</span>
        <span className="loader-item">3</span>
        <span className="loader-item">4</span>
        <span className="loader-item">5</span>
        <span className="loader-item">6</span>
      </div>
      <Typography.Text style={{ fontSize: 20, marginTop: 0 }}>{loadingMessage}</Typography.Text>
    </>
  );
};
