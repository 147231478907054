import { Form, Space } from 'antd';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { CreateProductMaster } from 'features/create/CreateProductMasterNew';
import { useFormikContext } from 'formik';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ProductMasterFormNavigation } from './ProductMasterFormNavigation';

export const CreateProductMasterForm: React.FC = () => {
  const { isSubmitting, values } = useFormikContext();

  return (
    <Space direction="vertical" style={{ position: 'relative' }}>
      <ProductMasterFormNavigation />
      <Form style={{ marginBottom: 16 }} layout="vertical">
        <Outlet />
        {isSubmitting && <BlockLoader direction="loader loader--slideUp" fullPageLoader loadingMessage={`Creating Product Master and Releasing to ${(values as CreateProductMaster).legalEntity}`} />}
      </Form>
    </Space>
  );
};
