import { Card, Col, Row } from 'antd';
import { MultipleConditionSelect } from 'components/atoms/MultipleConditionSelect';
import { MultipleDispositionSelect } from 'components/atoms/MultipleDispositionSelect';
import { MultipleOwnerSelect } from 'components/atoms/MultipleOwnerSelect';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';

export const DimensionsStep: React.FC = () => {
  const { productMasterId } = useParams();
  const { data: productMasterData } = useGetProductMasterQuery(productMasterId as string, { skip: !productMasterId });

  return (
    <>
      <Col style={{ padding: 10, background: 'rgb(206, 213, 242)' }}>
        <Row justify="space-between" align="middle">
          <AntPageTitle text="Dimensions" />
        </Row>
      </Col>
      <Card>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <MultipleOwnerSelect productMasterData={productMasterData} fieldName="productOwners" label="Owner" />
          </Col>
          <Col span={12}>
            <MultipleConditionSelect productMasterData={productMasterData} fieldName="productConditions" label="Condition" />
          </Col>
          <Col span={12}>
            <MultipleDispositionSelect productMasterData={productMasterData} fieldName="productDispositions" label="Disposition" />
          </Col>
        </Row>
      </Card>
    </>
  );
};
