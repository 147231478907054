import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Modal, Row, Select, Space, Spin } from 'antd';
import { Option } from 'antd/lib/mentions';
import { TextInput } from 'components/UI/FormItems';
import { FormLabel } from 'components/UI/FormItems/FormLabel';
import { FormikProvider, useField, useFormik } from 'formik';
import { UpdatedErrorModel } from 'models/ErrorModel';
import { ProductMaster } from 'models/ProductMaster';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CreateDimensionPayload, useCreateDispositionMutation, useGetDispositionsQuery } from 'redux/services/animals/animalsApi';
import { SizedLoader } from './SizedLoader';

interface Props {
  label: string;
  fieldName: string;
  productMasterData?: ProductMaster | undefined;
}

interface DispositionModel {
  description: string;
  dispositionId: string;
}

export const MultipleDispositionSelect: React.FC<Props> = ({ label, fieldName, productMasterData }: Props) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string[]>(fieldName);
  const [showModal, setShowModal] = useState(false);

  const { data, isLoading: isDispLoading } = useGetDispositionsQuery();
  const [createDisposition, { isLoading }] = useCreateDispositionMutation();
  const { pathname } = useLocation();

  const options = data?.data.map((entry) => <Option key={entry.name}>{entry.name}</Option>) || [];
  const defaultValues = productMasterData?.productMasterDispositions.map((disposition: DispositionModel) => disposition.dispositionId);

  const formik = useFormik<CreateDimensionPayload>({
    enableReinitialize: true,
    validationSchema: undefined,
    initialValues: {
      name: '',
      description: ''
    },
    onSubmit: async (values) => {
      try {
        const response = await createDisposition({ payload: values }).unwrap();

        message.success(`${response.name} was successfully created.`);
        setShowModal(false);
        setValue([...value, response.name]);
        formik.resetForm();
      } catch (error) {
        message.error((error as UpdatedErrorModel).data);
        // message.error();
      }
    }
  });

  const handleChange = (value: string[]): void => {
    setValue(value);
  };

  const handleCancel = (): void => {
    setShowModal(false);
    formik.resetForm();
  };

  return (
    <Spin spinning={isDispLoading} indicator={<SizedLoader medium />}>
      <Form.Item
        labelAlign="left"
        validateStatus={touched && error ? 'error' : 'success'}
        label={label ? <FormLabel label={label} /> : undefined}
        help={!pathname.includes('/overview') && touched && error ? error : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
          disabled={pathname.includes('edit-product-master') || pathname.includes('/overview')}
          onChange={handleChange}
          value={defaultValues || value}
          dropdownRender={(menu): JSX.Element => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Space style={{ padding: '0 8px 4px' }}>
                <Button onClick={(): void => setShowModal(true)} type="text" icon={<PlusOutlined />}>
                  Create Disposition
                </Button>
              </Space>
            </>
          )}
          mode="multiple"
          allowClear
          style={{ borderRadius: 5 }}>
          {options}
        </Select>
        <Modal onOk={formik.submitForm} okButtonProps={{ loading: formik.isSubmitting }} title="Create Disposition" open={showModal} onCancel={handleCancel}>
          <FormikProvider value={formik}>
            <Spin spinning={isLoading} indicator={<SizedLoader medium loadingMessage="Creating disposition..." />}>
              <Form layout="vertical">
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <TextInput label="Name" fieldName="name" allowClear style={{ borderRadius: 5 }} />
                  </Col>
                  <Col span={24}>
                    <TextInput label="Description" fieldName="description" allowClear style={{ borderRadius: 5 }} />
                  </Col>
                </Row>
              </Form>
            </Spin>
          </FormikProvider>
        </Modal>
      </Form.Item>
    </Spin>
  );
};
