import { Form, Select, Spin } from 'antd';
import { SelectValue } from 'antd/lib/select';
import { SizedLoader } from 'components/atoms/SizedLoader';
import { useField } from 'formik';
import React from 'react';
import { useGetAllManufacturersQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { FormLabel } from './FormLabel';

interface Props {
  fieldName: string;
  label: string;
}

export const ManufacturerSelectInput: React.FC<Props> = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const { data, isLoading } = useGetAllManufacturersQuery();
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  const manufacturerOptions = data?.map((manufacturer: any) => ({ label: manufacturer.manufacturerName, value: manufacturer.manufacturerId }));

  const handleSelect = (e: SelectValue) => {
    setValue(e as string);
  };

  return (
    <Spin spinning={isLoading} indicator={<SizedLoader medium />}>
      <Form.Item
        labelAlign="left"
        validateStatus={touched && error ? 'error' : 'success'}
        label={label ? <FormLabel label={label} /> : undefined}
        help={touched && error ? error : undefined}
        style={{ marginBottom: 0 }}
        labelCol={{ style: { padding: 0 } }}>
        <Select
          {...rest}
          defaultValue={value}
          onSelect={handleSelect}
          onClear={() => setValue('')}
          onFocus={() => setTouched(false)}
          onBlur={() => setTouched(true)}
          showSearch
          loading={isLoading}
          options={manufacturerOptions}
          allowClear
          style={{ width: '100%' }}
          defaultActiveFirstOption={true}></Select>
      </Form.Item>
    </Spin>
  );
};
