/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { Card, Col, Input, InputRef, Row, Table, Typography } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { useFormikContext } from 'formik';
import { CardPropsModel } from 'models/CardPropsModel';
import { CreateProductMastersModel } from 'models/ProductMaster';
import React, { useEffect, useRef, useState } from 'react';
import { VList } from 'virtual-table-ant-design';

interface DataType {
  key: string;
  ownerId: string;
  conditionId: number;
  dispositionId: string;
}

type DataIndex = keyof DataType;

export const VariantsStep: React.FC = () => {
  const { values, setValues } = useFormikContext<CreateProductMastersModel>();
  const [tableData, setTableData] = useState<CardPropsModel[]>([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setSelectedKeys(e.target.value ? [e.target.value] : []);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm({ closeDropdown: false });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${dataIndex}`}
          value={(selectedKeys as string[])[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e, setSelectedKeys, selectedKeys as string[], confirm, dataIndex)}
          // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId',
      width: '30%',
      sorter: (a, b): number => {
        if (a.ownerId < b.ownerId) {
          return -1;
        }
        if (a.ownerId > b.ownerId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('ownerId')
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId',
      width: '20%',
      sorter: (a, b): number => {
        if (a.conditionId < b.conditionId) {
          return -1;
        }
        if (a.conditionId > b.conditionId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('conditionId')
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      key: 'dispositionId',
      ...getColumnSearchProps('dispositionId'),
      sorter: (a, b): number => {
        if (a.dispositionId < b.dispositionId) {
          return -1;
        }
        if (a.dispositionId > b.dispositionId) {
          return 1;
        }

        return 0;
      },
      sortDirections: ['descend', 'ascend']
    }
  ];

  useEffect(() => {
    values.productOwners.forEach((ownerValue) =>
      values.productConditions.forEach((conditionValue) =>
        values.productDispositions.forEach((dispositionValue) => setTableData((prev) => [...prev, { ownerId: ownerValue, conditionId: conditionValue, dispositionId: dispositionValue }]))
      )
    );
  }, []);

  const handleSelectRow = (record: CardPropsModel | undefined, selected: boolean, selectedRows: CardPropsModel[]): void => {
    setValues((prev) => ({
      ...prev,
      productVariants: [...selectedRows]
    }));
  };

  const selectedRows = values.productVariants.map((variant) => `${variant.ownerId} - ${variant.conditionId} - ${variant.dispositionId}`);

  return (
    <>
      <Col style={{ padding: 10, background: '#b0b2bc' }}>
        <Row justify="space-between" align="middle">
          <AntPageTitle text="Product Variants" />
        </Row>
      </Col>
      <Card>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Table
              title={(): JSX.Element => (
                <Typography.Title level={5} style={{ marginBottom: 5 }}>
                  Possible Variants
                </Typography.Title>
              )}
              scroll={{ y: 500 }}
              components={VList({ height: 500 })}
              style={{ width: '100%' }}
              size="small"
              columns={columns}
              bordered={true}
              pagination={false}
              dataSource={tableData as any[]}
              rowSelection={{
                type: 'checkbox',
                onSelect: (record, selected, selectedRows): void => handleSelectRow(record, selected, selectedRows),
                onSelectAll: (selected, selectedRows): void => handleSelectRow(undefined, selected, selectedRows),
                defaultSelectedRowKeys: selectedRows
              }}
              rowKey={({ ownerId, conditionId, dispositionId }): string => `${ownerId} - ${conditionId} - ${dispositionId}`}
            />
          </Row>
        </Col>
      </Card>
    </>
  );
};
