export const customerLottie = {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 49,
  w: 576,
  h: 576,
  nm: 'Team',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [0, -101.317, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -100.749, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -99.048, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -96.212, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -92.242, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -87.137, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -80.898, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.524, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -74.017, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -76.749, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.348, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.812, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -78.142, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -76.337, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.398, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -72.137, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -73.117, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -72.962, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.673, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.781, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [0, -71.317, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 3, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 15, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 27, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 33, s: [2] },
            { t: 39, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 3, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 13, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 69.161, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -38.197],
                    [38.197, 0],
                    [0, 38.197],
                    [-38.197, 0]
                  ],
                  o: [
                    [0, 38.197],
                    [-38.197, 0],
                    [0, -38.197],
                    [38.197, 0]
                  ],
                  v: [
                    [69.161, 0],
                    [0, 69.161],
                    [-69.161, 0],
                    [0, -69.161]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [287.801, 407.897, 0], t: 10, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 408.464, 0], t: 11, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 410.166, 0], t: 12, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 413.002, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 416.972, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 422.077, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 428.316, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.689, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.197, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 432.464, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 430.866, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 430.402, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 431.072, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 432.877, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 435.816, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.077, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 436.097, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 436.252, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.541, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.433, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [287.801, 437.897, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 7',
      parent: 3,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 10, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 60.697, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [68.356, 0],
                    [3.158, -67.575]
                  ],
                  o: [
                    [-3.158, -67.575],
                    [-68.356, 0],
                    [0, 0]
                  ],
                  v: [
                    [127.289, 60.697],
                    [0, -60.697],
                    [-127.289, 60.697]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [-11.158, -75.647, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -75.08, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -73.378, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -70.542, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -66.572, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -61.467, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -55.228, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.855, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -48.347, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -51.08, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -52.678, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -53.142, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -52.472, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -50.667, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.728, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.467, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.447, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -47.292, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.003, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -46.111, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [-11.158, -45.647, 0], t: 35, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 4',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 5, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 17, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 29, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 35, s: [2] },
            { t: 41, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 5, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 15, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 39.778, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -21.969],
                    [21.969, 0],
                    [0, 21.969],
                    [-21.969, 0]
                  ],
                  o: [
                    [0, 21.969],
                    [-21.969, 0],
                    [0, -21.969],
                    [21.969, 0]
                  ],
                  v: [
                    [39.778, 0],
                    [0, 39.778],
                    [-39.778, 0],
                    [0, -39.778]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [436.627, 373.236, 0], t: 12, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 373.803, 0], t: 13, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 375.505, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 378.341, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 382.311, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 387.416, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 393.655, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.028, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 400.536, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 397.803, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 396.205, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 395.741, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 396.411, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 398.216, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.155, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.416, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.436, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 401.591, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.88, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 402.772, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [436.627, 403.236, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 5',
      parent: 7,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 2, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 12, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 36.655, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-19.803, 0],
                    [0, -40.487]
                  ],
                  o: [
                    [13.166, -12.762],
                    [40.487, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.143, -16.008],
                    [-11.167, -36.655],
                    [62.143, 36.655]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 3,
      nm: 'NULL CONTROL',
      parent: 12,
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [11.085, -75.647, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -75.08, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -73.378, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -70.542, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -66.572, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -61.467, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -55.228, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.855, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -48.347, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -51.08, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -52.678, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -53.142, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -52.472, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -50.667, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.728, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.467, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.447, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -47.292, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.003, 0], t: 35, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -46.111, 0], t: 36, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [11.085, -45.647, 0], t: 37, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 102,
      st: 12,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 2',
      parent: 9,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.313], y: [1] }, o: { x: [0.333], y: [0] }, t: 7, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.641], y: [0] }, t: 19, s: [6] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 31, s: [-4] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 37, s: [2] },
            { t: 43, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 7, s: [60, 60, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 17, s: [60, 30, 0] }
          ]
        },
        a: { a: 0, k: [0, 39.778, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -21.969],
                    [-21.969, 0],
                    [0, 21.969],
                    [21.969, 0]
                  ],
                  o: [
                    [0, 21.969],
                    [21.969, 0],
                    [0, -21.969],
                    [-21.969, 0]
                  ],
                  v: [
                    [-39.778, 0],
                    [0, 39.778],
                    [39.778, 0],
                    [0, -39.778]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 3,
      nm: 'NULL CONTROL',
      sr: 1,
      ks: {
        o: { a: 0, k: 0 },
        r: { a: 0, k: 0 },
        p: {
          k: [
            { s: [138.731, 373.236, 0], t: 14, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 373.803, 0], t: 15, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 375.505, 0], t: 16, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 378.341, 0], t: 17, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 382.311, 0], t: 18, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 387.416, 0], t: 19, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 393.655, 0], t: 20, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.028, 0], t: 21, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 400.536, 0], t: 22, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 397.803, 0], t: 23, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 396.205, 0], t: 24, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 395.741, 0], t: 25, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 396.411, 0], t: 26, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 398.216, 0], t: 27, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.155, 0], t: 28, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.416, 0], t: 29, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.436, 0], t: 30, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 401.591, 0], t: 31, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.88, 0], t: 32, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 402.772, 0], t: 33, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } },
            { s: [138.731, 403.236, 0], t: 34, i: { x: 1, y: 1 }, o: { x: 0, y: 0 } }
          ]
        },
        a: { a: 0, k: [60, 60, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 12,
      ty: 4,
      nm: 'Layer 3',
      parent: 11,
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: { a: 0, k: 0 },
        p: {
          a: 1,
          k: [
            { i: { x: 0, y: 1 }, o: { x: 0.333, y: 0 }, t: 4, s: [60, 90, 0], to: [0, -5, 0], ti: [0, 5, 0] },
            { t: 14, s: [60, 60, 0] }
          ]
        },
        a: { a: 0, k: [0, 36.655, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-40.487, 0],
                    [-13.166, -12.872]
                  ],
                  o: [
                    [0, -40.487],
                    [19.877, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.216, 36.655],
                    [11.094, -36.655],
                    [62.216, -15.861]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const headerInfoLottie = {
  v: '5.7.14',
  fr: 30,
  ip: 0,
  op: 26,
  w: 576,
  h: 576,
  nm: 'Student Notes',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 7',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.27], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.75], y: [0] }, t: 10, s: [31] },
            { t: 20, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.27, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [318.642, 390.457, 0], to: [0, 6.333, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.75, y: 0 }, t: 10, s: [318.642, 428.457, 0], to: [0, 0, 0], ti: [0, 6.333, 0] },
            { t: 20, s: [318.642, 390.457, 0] }
          ]
        },
        a: { a: 0, k: [318.642, 390.457, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.698, 47.271],
                    [18.765, -47.271],
                    [41.698, -32.604]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [1, 1, 0.250980407, 1] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [389.189, 213.608] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-9.305, -5.951],
                    [0, 0],
                    [5.951, -9.305]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [5.951, -9.305],
                    [0, 0],
                    [9.305, 5.951],
                    [0, 0]
                  ],
                  v: [
                    [-42.122, 98.937],
                    [-73.788, 111.329],
                    [-75.82, 77.386],
                    [38.969, -102.102],
                    [66.593, -108.176],
                    [66.593, -108.176],
                    [72.667, -80.551]
                  ],
                  c: true
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [394.462, 279.128] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 11',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [202.078, 368.327, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [0] },
              { t: 24, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 10',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [201.059, 314.624, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 12, s: [0] },
              { t: 22, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 9',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [225.041, 260.92, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-66.482, 0],
                    [66.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 10, s: [0] },
              { t: 20, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 8',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [236.522, 207.216, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-78.982, 0],
                    [78.982, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 0 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.5], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 8, s: [0] },
              { t: 18, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim In Path',
          hd: false
        }
      ],
      ip: 8,
      op: 98,
      st: 8,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 6',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [202.078, 368.327, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 6, s: [0] },
              { t: 16, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 5',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [201.059, 314.624, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-41.482, 0],
                    [41.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 4, s: [0] },
              { t: 14, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [225.041, 260.92, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-66.482, 0],
                    [66.482, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 2, s: [0] },
              { t: 12, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 9,
      ty: 4,
      nm: 'Layer 3',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [236.522, 207.216, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-78.982, 0],
                    [78.982, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        },
        {
          ty: 'tm',
          s: { a: 0, k: 100 },
          e: {
            a: 1,
            k: [
              { i: { x: [0.833], y: [0.833] }, o: { x: [0.5], y: [0] }, t: 0, s: [0] },
              { t: 10, s: [100] }
            ]
          },
          o: { a: 0, k: 0 },
          m: 1,
          nm: 'Trim Out Path',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 10,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [238.721, 287.735, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.27, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.392, 60.975]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.75, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.505, 143.475]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [-13.836, 0.04],
                          [0, 0],
                          [0, 13.779],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, 13.836],
                          [0, 0],
                          [13.779, -0.04],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [32.778, -180.786],
                          [-107.392, -180.786],
                          [-132.392, -155.786],
                          [-132.392, 155.786],
                          [-107.319, 180.786],
                          [107.465, 180.16],
                          [132.392, 155.16],
                          [132.392, 60.975]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 11,
      ty: 4,
      nm: 'Layer 1',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [350.056, 165.445, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.27, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.057, 58.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.75, y: 0 },
                    t: 10,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.17, 214.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 20,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [21.057, 58.496],
                          [21.057, -33.496],
                          [-3.943, -58.496],
                          [-21.057, -58.496]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const deliveryLottie = {
  v: '5.7.3',
  fr: 30,
  ip: 0,
  op: 64,
  w: 576,
  h: 576,
  nm: 'Truck',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 8',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [142.361, 94.986, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -25.871],
                    [25.871, 0],
                    [0, 25.871],
                    [-25.871, 0]
                  ],
                  o: [
                    [0, 25.871],
                    [-25.871, 0],
                    [0, -25.871],
                    [25.871, 0]
                  ],
                  v: [
                    [46.844, 0],
                    [0, 46.844],
                    [-46.844, 0],
                    [0, -46.844]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 7',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-113.156, 94.986, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, -25.871],
                    [25.871, 0],
                    [0, 25.871],
                    [-25.871, 0]
                  ],
                  o: [
                    [0, 25.871],
                    [-25.871, 0],
                    [0, -25.871],
                    [25.871, 0]
                  ],
                  v: [
                    [46.844, 0],
                    [0, 46.844],
                    [-46.844, 0],
                    [0, -46.844]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 6',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [75.725, 96.383, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-61.637, 15.417],
                    [66.637, 15.417],
                    [66.637, -15.417]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 3,
      nm: 'Null 27',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.667, y: 1 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [288, 288, 0], to: [-4.333, 0, 0], ti: [-4, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.333, y: 0 }, t: 20, s: [262, 288, 0], to: [4, 0, 0], ti: [-4.333, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.333, y: 0 }, t: 44, s: [312, 288, 0], to: [4.333, 0, 0], ti: [4, 0, 0] },
            { t: 64, s: [288, 288, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 5',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.667], y: [1] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 20, s: [0.2] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.333], y: [0] }, t: 40, s: [-0.3] },
            { t: 64, s: [0] }
          ],
          ix: 10
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [23.472, 1.444, 0], to: [0, -0.167, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 4, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 8, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 12, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 16, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 20, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 24, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 28, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 32, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 36, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 40, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 44, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 48, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 52, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 56, s: [23.472, 1.444, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 60, s: [23.472, 0.444, 0], to: [0, 0, 0], ti: [0, -0.167, 0] },
            { t: 64, s: [23.472, 1.444, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [101.11, 3.914, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [13.807, 0],
                    [0, 0],
                    [0, -13.807],
                    [0, 0],
                    [-13.807, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, -13.807],
                    [0, 0],
                    [-13.807, 0],
                    [0, 0],
                    [0, 13.807],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [142.363, 22.819],
                    [142.363, -86.8],
                    [117.363, -111.8],
                    [-117.363, -111.8],
                    [-142.363, -86.8],
                    [-142.363, 86.8],
                    [-117.363, 111.8],
                    [-85.113, 111.8]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 4',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [219.304, 1.8, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [75.556, 0],
                    [-75.556, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: 'Layer 3',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [158.887, 111.8, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [11.525, 0],
                    [-16.525, 0]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 2',
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [220, 18.05, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [-4.572, -4.05],
                    [0, 0],
                    [0, -7.156],
                    [0, 0],
                    [13.807, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [6.108, 0],
                    [0, 0],
                    [5.357, 4.745],
                    [0, 0],
                    [0, 13.807],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-77.637, -93.75],
                    [-19.342, -93.75],
                    [-2.766, -87.465],
                    [69.213, -23.711],
                    [77.637, -4.997],
                    [77.637, 68.75],
                    [52.637, 93.75],
                    [49.587, 93.75]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const otherLottie = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.0.0', a: 'Sadjad', k: 'Attachment', d: 'Animation icons', tc: 'none' },
  fr: 30,
  ip: 0,
  op: 34,
  w: 250,
  h: 250,
  nm: 'attachment_lottie',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: 'Null 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 11, s: [-11] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 14, s: [-11] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 19, s: [16] },
            { i: { x: [0.2], y: [1] }, o: { x: [0.8], y: [0] }, t: 24, s: [-7] },
            { t: 29, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [124.133, 97.569, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1 Outlines',
      parent: 1,
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [-1.057, 27.479, 0], ix: 2 }, a: { a: 0, k: [179.775, 183.658, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-8.369, -8.368],
                          [-8.368, 8.368],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-22.316, 22.317],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-8.369, 8.368],
                          [8.368, 8.368],
                          [0, 0],
                          [15.342, -15.342],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-34.299, 7.876],
                          [-34.299, 38.182],
                          [-3.995, 38.182],
                          [54.089, -19.902],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.209, 68.486],
                          [86.919, -2.225]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 11,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-10.15, -6.087],
                          [-6.086, 10.149],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-24.956, 19.319],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-6.087, 10.149],
                          [10.149, 6.086],
                          [0, 0],
                          [11.159, -18.607],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-22.632, 28.776],
                          [-15.276, 58.174],
                          [14.122, 50.818],
                          [56.369, -19.628],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.175, 68.064],
                          [95.251, 6.851]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 14,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-10.15, -6.087],
                          [-6.086, 10.149],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-24.956, 19.319],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-6.087, 10.149],
                          [10.149, 6.086],
                          [0, 0],
                          [11.159, -18.607],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-22.632, 28.776],
                          [-15.276, 58.174],
                          [14.122, 50.818],
                          [56.369, -19.628],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.175, 68.064],
                          [95.251, 6.851]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 19,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-6.457, -9.918],
                          [-9.918, 6.457],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-22.316, 22.317],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-9.919, 6.456],
                          [6.457, 9.918],
                          [0, 0],
                          [18.183, -11.838],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-42.213, -10.517],
                          [-48.48, 19.133],
                          [-18.831, 25.4],
                          [50.009, -19.417],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.21, 68.486],
                          [71.847, -14.598]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 24,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-9.592, -6.932],
                          [-6.932, 9.591],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-22.316, 22.317],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-6.933, 9.592],
                          [9.591, 6.932],
                          [0, 0],
                          [12.709, -17.585],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-30.87, 19.848],
                          [-26.054, 49.768],
                          [3.865, 44.952],
                          [51.981, -21.624],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.209, 68.486],
                          [95.442, 8.882]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 29,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [-8.369, -8.368],
                          [-8.368, 8.368],
                          [0, 0],
                          [15.342, 15.342],
                          [15.342, -15.342],
                          [0, 0],
                          [-22.316, -22.315],
                          [-22.316, 22.317],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [-8.369, 8.368],
                          [8.368, 8.368],
                          [0, 0],
                          [15.342, -15.342],
                          [-15.342, -15.342],
                          [0, 0],
                          [-22.316, 22.316],
                          [22.316, 22.317],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [1.056, -27.479],
                          [-34.299, 7.876],
                          [-34.299, 38.182],
                          [-3.995, 38.182],
                          [54.089, -19.902],
                          [54.089, -75.46],
                          [-1.469, -75.46],
                          [-64.604, -12.326],
                          [-64.604, 68.486],
                          [16.209, 68.486],
                          [86.919, -2.225]
                        ],
                        c: false
                      }
                    ]
                  }
                ],
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 13, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [179.775, 183.658], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const defectiveLottie = {
  v: '5.8.1',
  fr: 25,
  ip: 0,
  op: 30,
  w: 1080,
  h: 1080,
  nm: 'Comp 1',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: '32 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 0.833, y: 0.833 }, o: { x: 0.167, y: 0.167 }, t: 0, s: [540, 540, 0], to: [0, -8.833, 0], ti: [0, 8.833, 0] },
            { t: 25, s: [540, 487, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [6.975, 0],
                    [1.75, 0.578],
                    [-2.914, 8.733],
                    [0, 0],
                    [-4.24, 1.913],
                    [0, 0],
                    [-3.793, -8.399],
                    [8.39, -3.784],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [-1.75, 0],
                    [-8.732, -2.913],
                    [0, 0],
                    [1.473, -4.411],
                    [0, 0],
                    [8.374, -3.768],
                    [3.784, 8.39],
                    [0, 0],
                    [0, 0],
                    [-2.335, 6.983]
                  ],
                  v: [
                    [-71.964, 95.541],
                    [-77.238, 94.677],
                    [-87.777, 73.591],
                    [-52.157, -33.13],
                    [-43.196, -43.051],
                    [64.86, -91.773],
                    [86.907, -83.431],
                    [78.565, -61.385],
                    [-22.843, -15.657],
                    [-56.152, 84.138]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [323.737, 705.368], ix: 2 },
              a: { a: 0, k: [72, -78], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [-16] },
                  { t: 25, s: [0] }
                ],
                ix: 6
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.496, 0],
                    [0.537, 0.049],
                    [0, 0],
                    [2.799, 3.987],
                    [0, 0],
                    [-7.536, 5.298],
                    [-5.306, -7.536],
                    [0, 0],
                    [0, 0],
                    [0.871, -9.164]
                  ],
                  o: [
                    [-0.529, 0],
                    [0, 0],
                    [-4.85, -0.464],
                    [0, 0],
                    [-5.29, -7.528],
                    [7.544, -5.29],
                    [0, 0],
                    [0, 0],
                    [9.163, 0.879],
                    [-0.83, 8.618]
                  ],
                  v: [
                    [77.559, 63.632],
                    [75.957, 63.557],
                    [-19.674, 54.418],
                    [-31.726, 47.404],
                    [-89.717, -35.124],
                    [-85.657, -58.341],
                    [-62.439, -54.281],
                    [-8.859, 21.964],
                    [79.13, 30.37],
                    [94.137, 48.551]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [245.906, 575.385], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [5.134, 0],
                    [2.97, 2.173],
                    [0, 0],
                    [1.058, 3.418],
                    [0, 0],
                    [-8.797, 2.726],
                    [-2.727, -8.797],
                    [0, 0],
                    [0, 0],
                    [5.436, -7.431]
                  ],
                  o: [
                    [-3.418, 0],
                    [0, 0],
                    [-2.881, -2.116],
                    [0, 0],
                    [-2.727, -8.789],
                    [8.724, -2.718],
                    [0, 0],
                    [0, 0],
                    [7.422, 5.445],
                    [-3.264, 4.451]
                  ],
                  v: [
                    [57.622, 102.315],
                    [47.783, 99.092],
                    [-33.76, 39.327],
                    [-39.823, 30.815],
                    [-73.791, -78.747],
                    [-62.805, -99.597],
                    [-41.955, -88.611],
                    [-9.615, 15.694],
                    [67.493, 72.204],
                    [71.082, 95.504]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [265.812, 445.034], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.749, 0],
                    [2.336, 6.983],
                    [0, 0],
                    [0, 0],
                    [-3.784, 8.39],
                    [-8.366, -3.76],
                    [0, 0],
                    [-1.473, -4.411],
                    [0, 0],
                    [8.732, -2.913]
                  ],
                  o: [
                    [-6.983, 0],
                    [0, 0],
                    [0, 0],
                    [-8.391, -3.784],
                    [3.784, -8.399],
                    [0, 0],
                    [4.24, 1.913],
                    [0, 0],
                    [2.913, 8.733],
                    [-1.75, 0.578]
                  ],
                  v: [
                    [71.965, 95.536],
                    [56.152, 84.134],
                    [22.843, -15.661],
                    [-78.564, -61.39],
                    [-86.906, -83.435],
                    [-64.86, -91.777],
                    [43.196, -43.055],
                    [52.156, -33.134],
                    [87.777, 73.587],
                    [77.238, 94.673]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [674.262, 703.372], ix: 2 },
              a: { a: 0, k: [-74, -80], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 14, s: [16] },
                  { t: 25, s: [0] }
                ],
                ix: 6
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.529, 0],
                    [0.83, 8.626],
                    [-9.164, 0.879],
                    [0, 0],
                    [0, 0],
                    [-7.561, -5.29],
                    [5.289, -7.536],
                    [0, 0],
                    [4.85, -0.464],
                    [0, 0]
                  ],
                  o: [
                    [-8.496, 0],
                    [-0.872, -9.164],
                    [0, 0],
                    [0, 0],
                    [5.298, -7.528],
                    [7.527, 5.298],
                    [0, 0],
                    [-2.8, 3.98],
                    [0, 0],
                    [-0.537, 0.049]
                  ],
                  v: [
                    [-77.559, 63.632],
                    [-94.136, 48.551],
                    [-79.13, 30.37],
                    [8.858, 21.964],
                    [62.439, -54.288],
                    [85.665, -58.341],
                    [89.718, -35.116],
                    [31.718, 47.411],
                    [19.665, 54.418],
                    [-75.956, 63.557]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [754.093, 575.385], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 5',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.418, 0],
                    [3.263, 4.451],
                    [-7.422, 5.445],
                    [0, 0],
                    [0, 0],
                    [-8.805, -2.734],
                    [2.726, -8.789],
                    [0, 0],
                    [2.881, -2.116],
                    [0, 0]
                  ],
                  o: [
                    [-5.135, 0],
                    [-5.437, -7.431],
                    [0, 0],
                    [0, 0],
                    [2.718, -8.805],
                    [8.798, 2.726],
                    [0, 0],
                    [-1.058, 3.418],
                    [0, 0],
                    [-2.97, 2.173]
                  ],
                  v: [
                    [-57.621, 102.323],
                    [-71.081, 95.512],
                    [-67.492, 72.212],
                    [9.615, 15.702],
                    [41.956, -88.603],
                    [62.805, -99.589],
                    [73.792, -78.739],
                    [39.824, 30.823],
                    [33.761, 39.335],
                    [-47.782, 99.1]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [734.187, 445.026], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 6',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 6,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [8.007, 0],
                    [0.928, 0.154],
                    [-1.514, 9.082],
                    [-126.098, 13.282],
                    [-0.969, -9.156],
                    [9.155, -0.969],
                    [0.17, -1.017]
                  ],
                  o: [
                    [-0.904, 0],
                    [-9.074, -1.506],
                    [0.211, -1.262],
                    [9.115, -0.969],
                    [0.96, 9.147],
                    [-101.139, 10.644],
                    [-1.352, 8.155]
                  ],
                  v: [
                    [-66.707, 80.135],
                    [-69.458, 79.907],
                    [-83.162, 60.734],
                    [65.397, -79.167],
                    [83.716, -64.331],
                    [68.88, -46.012],
                    [-50.284, 66.202]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [576.722, 237.259], ix: 2 },
              a: { a: 0, k: [-68, 58], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [-14] },
                  { t: 25, s: [0] }
                ],
                ix: 6
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 7',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 7,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.903, 0],
                    [1.367, 8.146],
                    [100.383, 10.571],
                    [-0.96, 9.147],
                    [-9.13, -0.952],
                    [-0.212, -1.262],
                    [9.082, -1.506]
                  ],
                  o: [
                    [-8, 0],
                    [-0.765, -4.411],
                    [-9.155, -0.969],
                    [0.96, -9.156],
                    [126.091, 13.282],
                    [1.514, 9.082],
                    [-0.927, 0.154]
                  ],
                  v: [
                    [66.707, 80.127],
                    [50.284, 66.219],
                    [-68.881, -46.02],
                    [-83.716, -64.339],
                    [-65.398, -79.175],
                    [83.162, 60.726],
                    [69.457, 79.899]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [421.278, 241.267], ix: 2 },
              a: { a: 0, k: [66, 62], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: {
                a: 1,
                k: [
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 0, s: [0] },
                  { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 13, s: [14] },
                  { t: 25, s: [0] }
                ],
                ix: 6
              },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 8',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 8,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9.204, 0],
                    [0, 9.204],
                    [0, 0],
                    [-9.204, 0],
                    [0, -9.204],
                    [0, 0]
                  ],
                  o: [
                    [-9.204, 0],
                    [0, 0],
                    [0, -9.204],
                    [9.204, 0],
                    [0, 0],
                    [0, 9.204]
                  ],
                  v: [
                    [0.001, 277.656],
                    [-16.666, 260.991],
                    [-16.666, -260.989],
                    [0.001, -277.656],
                    [16.667, -260.989],
                    [16.667, 260.991]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [499.995, 622.344], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 9',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 9,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [50.211, 0],
                    [0, -50.204]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, -50.204],
                    [-50.204, 0],
                    [0, 0]
                  ],
                  v: [
                    [-91.051, 56.917],
                    [91.053, 56.917],
                    [91.053, 34.131],
                    [-0.003, -56.917],
                    [-91.051, 34.131]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [9.204, 0],
                    [0, 0],
                    [0, 9.204],
                    [0, 0],
                    [-68.587, 0],
                    [0, -68.588],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-9.204, 0],
                    [0, 0],
                    [0, -68.588],
                    [68.587, 0],
                    [0, 0],
                    [0, 9.204]
                  ],
                  v: [
                    [107.72, 90.25],
                    [-107.718, 90.25],
                    [-124.385, 73.584],
                    [-124.385, 34.131],
                    [-0.003, -90.25],
                    [124.385, 34.131],
                    [124.385, 73.584]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [499.999, 287.771], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 10',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 10,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, -24.674],
                    [0, 0],
                    [-88.16, 0],
                    [0, 88.159],
                    [0, 0],
                    [24.674, 0]
                  ],
                  o: [
                    [-24.666, 0],
                    [0, 0],
                    [0, 88.159],
                    [88.159, 0],
                    [0, 0],
                    [0, -24.674],
                    [0, 0]
                  ],
                  v: [
                    [-115.149, -244.323],
                    [-159.884, -199.58],
                    [-159.884, 84.445],
                    [-0.004, 244.324],
                    [159.883, 84.445],
                    [159.883, -199.58],
                    [115.141, -244.323]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [106.543, 0],
                    [0, 106.535],
                    [0, 0],
                    [-43.05, 0],
                    [0, 0],
                    [0, -43.05],
                    [0, 0]
                  ],
                  o: [
                    [-106.535, 0],
                    [0, 0],
                    [0, -43.05],
                    [0, 0],
                    [43.049, 0],
                    [0, 0],
                    [0, 106.535]
                  ],
                  v: [
                    [-0.004, 277.656],
                    [-193.217, 84.445],
                    [-193.217, -199.58],
                    [-115.149, -277.656],
                    [115.141, -277.656],
                    [193.216, -199.58],
                    [193.216, 84.445]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            { ty: 'mm', mm: 1, nm: 'Merge Paths 1', mn: 'ADBE Vector Filter - Merge', hd: false },
            { ty: 'fl', c: { a: 0, k: [0, 0, 0, 1], ix: 4 }, o: { a: 0, k: 100, ix: 5 }, r: 1, bm: 0, nm: 'Fill 1', mn: 'ADBE Vector Graphic - Fill', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [500, 622.344], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 11',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 11,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 30,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const serviceLottie = {
  v: '5.6.8',
  fr: 29.9700012207031,
  ip: 0,
  op: 60.0000024438501,
  w: 1080,
  h: 1080,
  nm: '12 Warehouse',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 2 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 20, s: [0] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 25, s: [10] },
            { i: { x: [0.833], y: [0.833] }, o: { x: [0.167], y: [0.167] }, t: 30, s: [-10] },
            { t: 35.0000014255792, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [315.106, 727.688, 0], ix: 2 },
        a: { a: 0, k: [146.319, 141.547, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.553, -60.167],
                    [62.553, -60.167],
                    [62.553, 60.167],
                    [-62.553, 60.167]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [146.319, 81.38], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.553, -60.167],
                    [62.553, -60.167],
                    [62.553, 60.167],
                    [-62.553, 60.167]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [208.872, 201.714], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-62.553, -60.167],
                    [62.553, -60.167],
                    [62.553, 60.167],
                    [-62.553, 60.167]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [83.766, 201.714], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 1 Outlines',
      sr: 1,
      ks: { o: { a: 0, k: 100, ix: 11 }, r: { a: 0, k: 0, ix: 10 }, p: { a: 0, k: [550.5, 607.355, 0], ix: 2 }, a: { a: 0, k: [354.5, 315.665, 0], ix: 1 }, s: { a: 0, k: [100, 100, 100], ix: 6 } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [169.426, 187.104],
                    [169.426, -187.105],
                    [-169.426, -187.105],
                    [-169.426, -53.561]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [351.015, 369.225], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [694, 556.332],
                    [244.212, 556.332]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [-273.86, -0.005],
                    [-273.86, -240.665],
                    [273.86, -240.665],
                    [273.86, 240.665]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [348.86, 315.665], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 3 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [544.859, 299.332, 0], ix: 2 },
        a: { a: 0, k: [420.141, 142.354, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 16.222],
                    [0, 0],
                    [16.221, 0],
                    [0, 0],
                    [0, -16.221],
                    [0, 0],
                    [-16.222, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, -16.222],
                    [0, 0],
                    [-16.222, 0],
                    [0, 0],
                    [0, 16.222],
                    [0, 0],
                    [16.222, 0]
                  ],
                  v: [
                    [345.141, 37.981],
                    [345.141, -37.982],
                    [315.768, -67.354],
                    [-315.768, -67.354],
                    [-345.141, -37.982],
                    [-345.141, 37.981],
                    [-315.768, 67.354],
                    [315.767, 67.354]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [420.141, 142.354], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 6 Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [547.018, 475.073, 0], ix: 2 },
        a: { a: 0, k: [184.422, -79.866, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 10, s: [100, 100, 100] },
            { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 20, s: [100, 0, 100] },
            { i: { x: [0.833, 0.833, 0.833], y: [0.833, 0.833, 0.833] }, o: { x: [0.167, 0.167, 0.167], y: [0.167, 0.167, 0.167] }, t: 35, s: [100, 0, 100] },
            { t: 45.0000018328876, s: [100, 100, 100] }
          ],
          ix: 6
        }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [15, 15],
                    [353.844, 15]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [15, 111.134],
                    [353.844, 111.134]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [77.617, 207.268],
                    [353.845, 207.268]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.54, 0.54, 0.54, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 30, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 3',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 150.000006109625,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const outageLottie = {
  v: '5.10.0',
  fr: 30,
  ip: 0,
  op: 26,
  w: 500,
  h: 500,
  nm: '45 dartboard',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Group 1',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            { i: { x: 1, y: 1 }, o: { x: 1, y: 0.002 }, t: 0, s: [769.739, -285.739, 0], to: [-77.333, 80, 0], ti: [77.333, -80, 0] },
            { t: 9, s: [305.739, 194.261, 0] }
          ],
          ix: 2,
          l: 2
        },
        a: { a: 0, k: [14.797, -7.191, 0], ix: 1, l: 2 },
        s: { a: 0, k: [600, 600, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [28.507, -14.52],
                        [22.125, -14.52],
                        [22.125, -20.901]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [24.235, -10.248],
                        [17.854, -10.248],
                        [17.854, -16.63]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0]
                      ],
                      o: [
                        [0, 0],
                        [0, 0]
                      ],
                      v: [
                        [1.087, 6.519],
                        [26.549, -18.943]
                      ],
                      c: false
                    },
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Group 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [241.486, 303.514, 0], ix: 2, l: 2 },
        a: { a: 0, k: [4.088, 11.018, 0], ix: 1, l: 2 },
        s: { a: 0, k: [600, 600, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 7,
                        s: [
                          {
                            i: [
                              [0, -3.578],
                              [3.578, 0],
                              [0, 3.578],
                              [-3.578, 0]
                            ],
                            o: [
                              [0, 3.578],
                              [-3.578, 0],
                              [0, -3.578],
                              [3.578, 0]
                            ],
                            v: [
                              [7.566, 6.518],
                              [1.088, 12.996],
                              [-5.39, 6.518],
                              [1.088, 0.04]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 10,
                        s: [
                          {
                            i: [
                              [0, -3.322],
                              [3.322, 0],
                              [0, 3.322],
                              [-3.322, 0]
                            ],
                            o: [
                              [0, 3.322],
                              [-3.322, 0],
                              [0, -3.322],
                              [3.322, 0]
                            ],
                            v: [
                              [7.102, 6.518],
                              [1.088, 12.533],
                              [-4.927, 6.518],
                              [1.088, 0.503]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 13,
                        s: [
                          {
                            i: [
                              [0, -3.867],
                              [3.867, 0],
                              [0, 3.867],
                              [-3.867, 0]
                            ],
                            o: [
                              [0, 3.867],
                              [-3.867, 0],
                              [0, -3.867],
                              [3.867, 0]
                            ],
                            v: [
                              [8.09, 6.518],
                              [1.088, 13.521],
                              [-5.915, 6.518],
                              [1.088, -0.485]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 16,
                        s: [
                          {
                            i: [
                              [0, -3.578],
                              [3.578, 0],
                              [0, 3.578],
                              [-3.578, 0]
                            ],
                            o: [
                              [0, 3.578],
                              [-3.578, 0],
                              [0, -3.578],
                              [3.578, 0]
                            ],
                            v: [
                              [7.566, 6.518],
                              [1.088, 12.996],
                              [-5.39, 6.518],
                              [1.088, 0.04]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 1',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 9,
                        s: [
                          {
                            i: [
                              [6.909, 0],
                              [0, 6.908],
                              [-6.909, 0],
                              [0, -6.908]
                            ],
                            o: [
                              [-6.909, 0],
                              [0, -6.908],
                              [6.909, 0],
                              [0, 6.908]
                            ],
                            v: [
                              [1.088, 19.047],
                              [-11.442, 6.518],
                              [1.088, -6.012],
                              [13.617, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 12,
                        s: [
                          {
                            i: [
                              [6.415, 0],
                              [0, 6.414],
                              [-6.415, 0],
                              [0, -6.414]
                            ],
                            o: [
                              [-6.415, 0],
                              [0, -6.414],
                              [6.415, 0],
                              [0, 6.414]
                            ],
                            v: [
                              [1.088, 18.151],
                              [-10.546, 6.518],
                              [1.088, -5.116],
                              [12.721, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 15,
                        s: [
                          {
                            i: [
                              [7.468, 0],
                              [0, 7.468],
                              [-7.468, 0],
                              [0, -7.468]
                            ],
                            o: [
                              [-7.468, 0],
                              [0, -7.468],
                              [7.468, 0],
                              [0, 7.468]
                            ],
                            v: [
                              [1.088, 20.062],
                              [-12.456, 6.518],
                              [1.088, -7.026],
                              [14.632, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 18,
                        s: [
                          {
                            i: [
                              [6.909, 0],
                              [0, 6.908],
                              [-6.909, 0],
                              [0, -6.908]
                            ],
                            o: [
                              [-6.909, 0],
                              [0, -6.908],
                              [6.909, 0],
                              [0, 6.908]
                            ],
                            v: [
                              [1.088, 19.047],
                              [-11.442, 6.518],
                              [1.088, -6.012],
                              [13.617, 6.518]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 2',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'gr',
              it: [
                {
                  ind: 0,
                  ty: 'sh',
                  ix: 1,
                  ks: {
                    a: 1,
                    k: [
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 11,
                        s: [
                          {
                            i: [
                              [10.245, 0],
                              [0, 10.245],
                              [-10.245, 0],
                              [0, -10.245]
                            ],
                            o: [
                              [-10.245, 0],
                              [0, -10.245],
                              [10.245, 0],
                              [0, 10.245]
                            ],
                            v: [
                              [1.088, 25.099],
                              [-17.493, 6.518],
                              [1.088, -12.063],
                              [19.669, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 14,
                        s: [
                          {
                            i: [
                              [9.513, 0],
                              [0, 9.513],
                              [-9.513, 0],
                              [0, -9.513]
                            ],
                            o: [
                              [-9.513, 0],
                              [0, -9.513],
                              [9.513, 0],
                              [0, 9.513]
                            ],
                            v: [
                              [1.088, 23.77],
                              [-16.165, 6.518],
                              [1.088, -10.734],
                              [18.34, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        i: { x: 0.667, y: 1 },
                        o: { x: 0.333, y: 0 },
                        t: 17,
                        s: [
                          {
                            i: [
                              [11.075, 0],
                              [0, 11.075],
                              [-11.075, 0],
                              [0, -11.075]
                            ],
                            o: [
                              [-11.075, 0],
                              [0, -11.075],
                              [11.075, 0],
                              [0, 11.075]
                            ],
                            v: [
                              [1.088, 26.604],
                              [-18.998, 6.518],
                              [1.088, -13.568],
                              [21.173, 6.518]
                            ],
                            c: true
                          }
                        ]
                      },
                      {
                        t: 20,
                        s: [
                          {
                            i: [
                              [10.245, 0],
                              [0, 10.245],
                              [-10.245, 0],
                              [0, -10.245]
                            ],
                            o: [
                              [-10.245, 0],
                              [0, -10.245],
                              [10.245, 0],
                              [0, 10.245]
                            ],
                            v: [
                              [1.088, 25.099],
                              [-17.493, 6.518],
                              [1.088, -12.063],
                              [19.669, 6.518]
                            ],
                            c: true
                          }
                        ]
                      }
                    ],
                    ix: 2
                  },
                  nm: 'Path 1',
                  mn: 'ADBE Vector Shape - Group',
                  hd: false
                },
                {
                  ty: 'st',
                  c: { a: 0, k: [0, 0, 0, 1], ix: 3 },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 2, ix: 5 },
                  lc: 2,
                  lj: 1,
                  ml: 10,
                  bm: 0,
                  nm: 'Stroke 1',
                  mn: 'ADBE Vector Graphic - Stroke',
                  hd: false
                },
                {
                  ty: 'tr',
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: 'Transform'
                }
              ],
              nm: 'Group 3',
              np: 2,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: 'ADBE Vector Group',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 2',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      ct: 1,
      bm: 0
    }
  ],
  markers: []
};

export const productsLottie = {
  v: '5.8.1',
  fr: 30,
  ip: 0,
  op: 40,
  w: 576,
  h: 576,
  nm: 'Label',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.535], y: [0] }, t: 10, s: [-15] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 22, s: [15] },
            { t: 32, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [153.146, 120.392, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-25.856, -33.396, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [25.856, 33.396],
                    [-25.856, -33.396]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 3',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 3, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.535], y: [0] }, t: 13, s: [-67.444] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.333], y: [0] }, t: 25, s: [67.95] },
            { t: 35, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [-4.293, 4.459, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.192, -2.962],
                    [26.787, -19.828],
                    [19.828, 26.787],
                    [-26.787, 19.828],
                    [-6.671, 1.941]
                  ],
                  o: [
                    [2.592, 2.439],
                    [19.828, 26.787],
                    [-26.787, 19.828],
                    [-19.828, -26.787],
                    [5.911, -4.375],
                    [0, 0]
                  ],
                  v: [
                    [41.305, -45.203],
                    [48.502, -37.099],
                    [35.902, 47.305],
                    [-48.502, 34.705],
                    [-35.902, -49.699],
                    [-16.874, -59.15]
                  ],
                  c: false
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0, 0, 0, 0], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 2',
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-76.721, -90.285, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [1.182, -9.293],
                    [9.293, 1.182],
                    [-1.182, 9.293],
                    [-9.293, -1.182]
                  ],
                  o: [
                    [-1.182, 9.293],
                    [-9.293, -1.182],
                    [1.182, -9.293],
                    [9.293, 1.182]
                  ],
                  v: [
                    [16.826, 2.141],
                    [-2.141, 16.826],
                    [-16.826, -2.141],
                    [2.141, -16.826]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 1',
      parent: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.414], y: [1] }, o: { x: [0.88], y: [0] }, t: 5, s: [0] },
            { i: { x: [0.509], y: [1] }, o: { x: [0.644], y: [0] }, t: 15, s: [-7.666] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.517], y: [0] }, t: 27, s: [7.526] },
            { t: 37, s: [0] }
          ],
          ix: 10
        },
        p: { a: 0, k: [5.964, 7.762, 0], ix: 2, l: 2 },
        a: { a: 0, k: [-123, -150, 0], ix: 1, l: 2 },
        s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [10.917, -8.453],
                    [0, 0],
                    [8.453, 10.917],
                    [0, 0],
                    [-0.56, 6.281],
                    [0, 0],
                    [-12.371, -2.049],
                    [0, 0],
                    [-3.86, -4.986],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [-10.917, 8.453],
                    [0, 0],
                    [-3.86, -4.986],
                    [0, 0],
                    [1.114, -12.49],
                    [0, 0],
                    [6.221, 1.03],
                    [0, 0],
                    [8.453, 10.917]
                  ],
                  v: [
                    [130.989, 62.099],
                    [18.79, 148.968],
                    [-16.282, 144.505],
                    [-135.453, -9.415],
                    [-140.586, -26.941],
                    [-130.984, -134.561],
                    [-105.997, -153.907],
                    [0.598, -136.252],
                    [16.281, -126.893],
                    [135.452, 27.027]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1], ix: 3 },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 17.5, ix: 5 },
              lc: 2,
              lj: 1,
              ml: 10,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};

export const reviewOrderLottie = {
  v: '5.7.14',
  fr: 30,
  ip: 0,
  op: 31,
  w: 576,
  h: 576,
  nm: 'We Design Service',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 7',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [287.861, 253.14, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0.2, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [117.014, -145.567],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [59.329, -145.567]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 0.8, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [-74.986, -145.831],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [-132.671, -145.831]
                        ],
                        c: false
                      }
                    ]
                  },
                  {
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [13.807, 0],
                          [0, 0],
                          [0, 13.807],
                          [0, 0],
                          [-13.807, 0],
                          [0, 0],
                          [0, -13.807],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [117.014, -145.567],
                          [155.887, -145.567],
                          [180.887, -120.567],
                          [180.887, 120.567],
                          [155.887, 145.567],
                          [-155.887, 145.567],
                          [-180.887, 120.567],
                          [-180.887, -120.567],
                          [-155.887, -145.567],
                          [59.329, -145.567]
                        ],
                        c: false
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 6',
      parent: 3,
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [33.733, -33.732, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [22.992, 22.992],
                    [-22.992, -22.992]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 5',
      sr: 1,
      ks: {
        o: { a: 0, k: 100 },
        r: {
          a: 1,
          k: [
            { i: { x: [0.2], y: [1] }, o: { x: [0.333], y: [0] }, t: 0, s: [0] },
            { i: { x: [0.667], y: [1] }, o: { x: [0.8], y: [0] }, t: 15, s: [-89] },
            { t: 30, s: [0] }
          ]
        },
        p: {
          a: 1,
          k: [
            { i: { x: 0.2, y: 1 }, o: { x: 0.333, y: 0 }, t: 0, s: [191.311, 354.602, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { i: { x: 0.667, y: 1 }, o: { x: 0.8, y: 0 }, t: 15, s: [390.544, 354.602, 0], to: [0, 0, 0], ti: [0, 0, 0] },
            { t: 30, s: [191.311, 354.602, 0] }
          ]
        },
        a: { a: 0, k: [-96.55, 96.555, 0] },
        s: { a: 0, k: [100, 100, 100] }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 1,
                k: [
                  {
                    i: { x: 0, y: 1 },
                    o: { x: 0.333, y: 0 },
                    t: 0,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [96.55, -50.566],
                          [50.561, -96.555],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    i: { x: 0.667, y: 1 },
                    o: { x: 1, y: 0 },
                    t: 15,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [74.667, -28.705],
                          [28.678, -74.694],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  },
                  {
                    t: 30,
                    s: [
                      {
                        i: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        o: [
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0],
                          [0, 0]
                        ],
                        v: [
                          [96.55, -50.566],
                          [50.561, -96.555],
                          [-84.561, 38.567],
                          [-96.55, 96.555],
                          [-38.572, 84.556]
                        ],
                        c: true
                      }
                    ]
                  }
                ]
              },
              nm: 'Path 1',
              hd: false
            },
            { ty: 'st', c: { a: 0, k: [0, 0, 0, 0] }, o: { a: 0, k: 100 }, w: { a: 0, k: 17.5 }, lc: 2, lj: 2, bm: 0, nm: 'Stroke 1', hd: false },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 4',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [322.09, 433.678, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, -34.971],
                    [0, 34.971]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 3',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [253.632, 433.678, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [0, 34.971],
                    [0, -34.971]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: { o: { a: 0, k: 100 }, r: { a: 0, k: 0 }, p: { a: 0, k: [287.861, 468.65, 0] }, a: { a: 0, k: [0, 0, 0] }, s: { a: 0, k: [100, 100, 100] } },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0]
                  ],
                  o: [
                    [0, 0],
                    [0, 0]
                  ],
                  v: [
                    [91.696, 0],
                    [-91.696, 0]
                  ],
                  c: false
                }
              },
              nm: 'Path 1',
              hd: false
            },
            {
              ty: 'st',
              c: { a: 0, k: [0.031372550875, 0.031372550875, 0.031372550875, 1] },
              o: { a: 0, k: 100 },
              w: { a: 0, k: 17.5 },
              lc: 2,
              lj: 2,
              bm: 0,
              nm: 'Stroke 1',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0] },
              a: { a: 0, k: [0, 0] },
              s: { a: 0, k: [100, 100] },
              r: { a: 0, k: 0 },
              o: { a: 0, k: 100 },
              sk: { a: 0, k: 0 },
              sa: { a: 0, k: 0 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          bm: 0,
          hd: false
        }
      ],
      ip: 0,
      op: 90,
      st: 0,
      bm: 0
    }
  ],
  markers: []
};
