import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
  isCreating: boolean;
  selectedReleasedEntity?: string;
}

const initialState: InitialState = {
  isCreating: false,
  selectedReleasedEntity: undefined
};

export const createProductMasterStateSlice = createSlice({
  name: 'productMasterState',
  initialState,
  reducers: {
    setIsCreating: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreating = payload;
    },
    setSelectedReleasedEntity: (state, { payload }: PayloadAction<string | undefined>) => {
      state.selectedReleasedEntity = payload;
    }
  }
});

export const { setIsCreating, setSelectedReleasedEntity } = createProductMasterStateSlice.actions;
