import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, message, Modal, Row, Select, Space, Typography } from 'antd';
import { Option } from 'antd/lib/mentions';
import { TextInput } from 'components/UI/FormItems';
import { FormikProvider, useField, useFormik } from 'formik';
import { UpdatedErrorModel } from 'models/ErrorModel';
import { ProductMaster } from 'models/ProductMaster';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateDimensionPayload, useCreateDispositionMutation, useGetDispositionsQuery } from 'redux/services/animals/animalsApi';
import { setDispositionIds } from 'redux/slices/ocdSlice';
import { RootState } from 'redux/store';

interface Props {
  label: string;
  productMasterData?: ProductMaster | undefined;
}

interface DispositionModel {
  description: string;
  dispositionId: string;
}

export const EditDispositionTags: React.FC<Props> = ({ label, productMasterData }: Props) => {
  const dispatch = useDispatch();
  const { data } = useGetDispositionsQuery();
  const { dispositionIds } = useSelector((state: RootState) => state.stagedOcd);
  const [{ value: formValue }, , { setValue }] = useField('productDispositions');

  const [showModal, setShowModal] = useState(false);
  const [createDisposition, { isLoading }] = useCreateDispositionMutation();

  const defaultValues = productMasterData?.productMasterDispositions
    .map((disposition: DispositionModel) => disposition.dispositionId)
    .sort((a, b) => {
      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }

      return 0;
    });

  const formik = useFormik<CreateDimensionPayload>({
    enableReinitialize: true,
    validationSchema: undefined,
    initialValues: {
      name: '',
      description: ''
    },
    onSubmit: async (values) => {
      try {
        const response = await createDisposition({ payload: values }).unwrap();

        message.success(`${response.name} was successfully created.`);
        await formik.resetForm();
      } catch (error) {
        message.error((error as UpdatedErrorModel).data);
        // message.error();
      }
    }
  });

  const handleCancel = (): void => {
    setShowModal(false);
    formik.resetForm();
  };

  const options = data?.data.map((disposition) => (
    <Option key={disposition.name} value={disposition.name} disabled={defaultValues?.includes(disposition.name)}>
      {disposition.name}
    </Option>
  ));

  const handleSelect = (value: string): void => {
    dispatch(setDispositionIds([...dispositionIds, value]));
    setValue([...formValue, value]);
  };

  const handleDeselect = (value: string): void => {
    dispatch(setDispositionIds(dispositionIds.filter((dispositionId) => dispositionId !== value)));
    setValue(formValue.filter((ownerId: string) => ownerId !== value));
  };

  return (
    <>
      <Row style={{ marginBottom: 10 }}>
        <Typography.Text style={{ fontSize: 16, fontWeight: 600 }}>{label}:</Typography.Text>
      </Row>
      <Row>
        <Col span={24}>
          <Select
            style={{ width: '100%' }}
            dropdownRender={(menu): JSX.Element => (
              <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <Space style={{ padding: '0 8px 4px' }}>
                  <Button onClick={(): void => setShowModal(true)} type="text" icon={<PlusOutlined />}>
                    Create Disposition
                  </Button>
                </Space>
              </>
            )}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            defaultValue={defaultValues}
            mode="tags">
            {options}
          </Select>
        </Col>
      </Row>
      <Modal
        closable={!formik.isSubmitting}
        onOk={formik.submitForm}
        cancelButtonProps={{ disabled: formik.isSubmitting }}
        okButtonProps={{ loading: formik.isSubmitting }}
        title="Create Disposition"
        visible={showModal}
        onCancel={handleCancel}>
        <FormikProvider value={formik}>
          <Form layout="vertical">
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <TextInput label="Name" fieldName="name" allowClear style={{ borderRadius: 5 }} />
              </Col>
              <Col span={24}>
                <TextInput label="Description" fieldName="description" allowClear style={{ borderRadius: 5 }} />
              </Col>
            </Row>
          </Form>
        </FormikProvider>
      </Modal>
    </>
  );
};
