import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LegalEntityParams } from 'models/LegalEntities';

const initialState: LegalEntityParams = {
  includeDeletedData: undefined,
  includeInactiveData: undefined,
  returnDeletedDataOnly: undefined,
  returnInactiveDataOnly: undefined,
  orderByDirection: undefined,
  orderByField: undefined,
  legalEntityNameContains: undefined,
  dataAreaIdContains: undefined
};

export const maggieCadabbyParams = createSlice({
  name: 'maggieCadabbyParams',
  initialState,
  reducers: {
    setIncludeDeletedData: (state, { payload }: PayloadAction<LegalEntityParams['includeDeletedData']>) => {
      state.includeDeletedData = payload;
    },
    setIncludeInactiveData: (state, { payload }: PayloadAction<LegalEntityParams['includeInactiveData']>) => {
      state.includeInactiveData = payload;
    },
    setReturnDeletedDataOnly: (state, { payload }: PayloadAction<LegalEntityParams['returnDeletedDataOnly']>) => {
      state.returnDeletedDataOnly = payload;
    },
    setReturnInactiveDataOnly: (state, { payload }: PayloadAction<LegalEntityParams['returnInactiveDataOnly']>) => {
      state.returnInactiveDataOnly = payload;
    },
    setOrderByDirection: (state, { payload }: PayloadAction<LegalEntityParams['orderByDirection']>) => {
      state.orderByDirection = payload;
    },
    setOrderByField: (state, { payload }: PayloadAction<LegalEntityParams['orderByField']>) => {
      state.orderByField = payload;
    },
    setDataAreaIdContains: (state, { payload }: PayloadAction<LegalEntityParams['dataAreaIdContains']>) => {
      state.dataAreaIdContains = payload;
    },
    setLegalEntityNameContains: (state, { payload }: PayloadAction<LegalEntityParams['legalEntityNameContains']>) => {
      state.legalEntityNameContains = payload;
    }
  }
});

export const {
  setIncludeDeletedData,
  setIncludeInactiveData,
  setOrderByDirection,
  setReturnInactiveDataOnly,
  setReturnDeletedDataOnly,
  setOrderByField,
  setDataAreaIdContains,
  setLegalEntityNameContains
} = maggieCadabbyParams.actions;
