import { Form, Select } from 'antd';
import { LabeledValue, SelectProps } from 'antd/lib/select';
import { useField } from 'formik';
import { FormLabel } from './FormLabel';

interface Props extends SelectProps<any> {
  fieldName: string;
  label?: string;
}

export const SelectInput = ({ fieldName, label, ...rest }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<string>(fieldName);

  return (
    <Form.Item
      labelAlign="left"
      validateStatus={touched && error ? 'error' : 'success'}
      label={label ? <FormLabel label={label} /> : undefined}
      help={touched && error ? error : undefined}
      style={{ marginBottom: 0 }}
      labelCol={{ style: { padding: 0 } }}>
      <Select
        {...rest}
        value={value || undefined}
        onSelect={(value: string | number | LabeledValue) => setValue(value.toString())}
        onFocus={() => setTouched(false)}
        onBlur={() => setTouched(true)}
        style={{ borderRadius: 5 }}
      />
    </Form.Item>
  );
};
