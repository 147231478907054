import { SearchOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Card, Col, Input, InputRef, Row, Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { VList } from 'virtual-table-ant-design';
import { BlockLoader } from './BlockLoader';
import WimsicalError from './wimsical-error/WimsicalError';

interface DataType {
  key: string;
  ownerId: string;
  conditionId: number;
  dispositionId: string;
}

type DataIndex = keyof DataType;

export const CreatedProductVariantsTable: React.FC = () => {
  const { productMasterId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ): void => {
    setSelectedKeys(e.target.value ? [e.target.value] : []);

    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm({ closeDropdown: false });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${dataIndex}`}
          value={(selectedKeys as string[])[0]}
          onChange={(e) => handleSearch(e, setSelectedKeys, selectedKeys as string[], confirm, dataIndex)}
          // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId',
      defaultSortOrder: 'ascend',
      width: '30%',
      sorter: (a, b): number => {
        if (a.ownerId < b.ownerId) {
          return -1;
        }
        if (a.ownerId > b.ownerId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('ownerId')
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId',
      defaultSortOrder: 'ascend',
      width: '30%',
      sorter: (a, b): number => {
        if (a.conditionId < b.conditionId) {
          return -1;
        }
        if (a.conditionId > b.conditionId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('conditionId')
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      defaultSortOrder: 'ascend',
      key: 'dispositionId',
      ...getColumnSearchProps('dispositionId'),
      sorter: (a, b): number => {
        if (a.dispositionId < b.dispositionId) {
          return -1;
        }
        if (a.dispositionId > b.dispositionId) {
          return 1;
        }

        return 0;
      },
      sortDirections: ['descend', 'ascend']
    }
  ];

  const { data: productMasterData, isLoading, isFetching, isError, error } = useGetProductMasterQuery(productMasterId ?? skipToken);

  if (isError) return <WimsicalError hasRedirect={false} statusCode={404} title='"An error occured, the team has been notified"' />;

  // if (!productMasterData) return <BlockLoader direction="loader loader--slideUp" />;
  const productMasterVariants = productMasterData?.productMasterVariants ?? [];

  const tableData = productMasterVariants.map(({ productOwnerId, productConditionId, productDispositionId }) => ({
    ownerId: productOwnerId,
    conditionId: productConditionId,
    dispositionId: productDispositionId
  }));

  return (
    <>
      <Col style={{ padding: 10, background: '#18a79984' }}>
        <Row justify="space-between" align="middle" style={{ padding: 0, margin: 2 }}>
          <AntPageTitle text="Assigned Product Variants" />
        </Row>
      </Col>
      <Card>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Table
              scroll={{ y: 500 }}
              components={VList({ height: 500 })}
              style={{ width: '100%' }}
              size="small"
              columns={columns}
              pagination={false}
              loading={{
                indicator: <BlockLoader style={{ marginTop: 55 }} direction="loader loader--slideUp" />,
                spinning: isLoading || isFetching
              }}
              bordered={true}
              dataSource={tableData as any[]}
              rowKey={({ ownerId, conditionId, dispositionId }): string => `${ownerId} - ${conditionId} - ${dispositionId}`}
            />
          </Row>
        </Col>
      </Card>
    </>
  );
};
