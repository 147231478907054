import { ImportOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Menu, Row, Upload, message } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { CreateProductMasterVariantsModel } from 'models/CreateProductMasterVariantsModel';
import { CreateReleasedProductVariantsPayloadModel } from 'models/CreateReleasedProductVariantsPayloadModel';
import { ImportReleasedProductPayloadModel } from 'models/ImportReleasedProductPayloadModel';
import { ImportedProductMasterModel } from 'models/ImportedProductMasterModel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setImportedProductMasterVariants, setImportedProductMasters, setImportedReleasedProductVariants, setImportedReleasedProducts } from 'redux/importedData/imported-data-slice';
import XLSX from 'xlsx';

export const ImportDropdown = (): JSX.Element => {
  const [selectedImport, setSelectedImport] = useState<string | null>(null);
  const [showSpinner, setShowSpiner] = useState<boolean>(true);
  const [parsedData, setParsedData] = useState<any>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hover, setHover] = useState('');

  const parseData = (file: RcFile): boolean => {
    const reader = new FileReader();

    // Only .XLSX formatted files will have this type
    // Reject all non .XLSX files

    reader.onload = function (e): void {
      if (e.target !== null && e.target.result !== null) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });

        workbook.SheetNames.forEach(function (sheetName) {
          const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]) as unknown[];

          if (excelData.length) {
            setParsedData(excelData);
          } else {
            message.error(`There was an issue importing that file. Please ensure it's not emtpy & try again.`);
            setShowSpiner(false);
          }
        });
      }
    };

    reader.readAsArrayBuffer(file);

    return false;
  };
  const beforeUpload = (importTitle: string, File: RcFile): boolean => {
    if (File.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('You can only import files with the extension .xlsx');
      setShowSpiner(false);

      return false;
    }
    if (selectedImport === null) {
      setSelectedImport(importTitle);
    }

    if (showSpinner !== true) {
      setShowSpiner(true);
    }
    parseData(File);

    return false;
  };

  useEffect(() => {
    if (parsedData !== null && selectedImport !== null) {
      switch (selectedImport) {
        case 'product-masters': {
          const importedProductMasters = parsedData as ImportedProductMasterModel[];

          dispatch(setImportedProductMasters(importedProductMasters));
          navigate(`/import/product-masters`);
          break;
        }

        case 'product-master-variants': {
          const importedProductMasterVariants = parsedData as CreateProductMasterVariantsModel[];

          dispatch(setImportedProductMasterVariants(importedProductMasterVariants));
          navigate(`/import/product-masters-variants`);
          break;
        }

        case 'released-products': {
          const importedReleasedProducts = parsedData as ImportReleasedProductPayloadModel[];

          dispatch(setImportedReleasedProducts(importedReleasedProducts));
          navigate(`/import/released-products`);
          break;
        }

        case 'released-product-variants': {
          const importedReleasedProductVariants = parsedData as CreateReleasedProductVariantsPayloadModel[];

          dispatch(setImportedReleasedProductVariants(importedReleasedProductVariants));
          navigate(`/import/released-product-variants`);
          break;
        }
      }
    }
  }, [parsedData, selectedImport]);
  const menu = (
    <Menu disabledOverflow={true} style={{ width: 250 }}>
      <Menu.Item onMouseEnter={() => setHover('1')} onMouseLeave={() => setHover('')} style={{ background: hover === '1' ? '#EDFBFF' : undefined }}>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('product-masters', File)}>
          <Row justify="space-between" style={{ color: '#289AFF', width: '100%' }}>
            <Col>Product Masters</Col>
            <Col>
              <ImportOutlined />
            </Col>
          </Row>
        </Upload>
      </Menu.Item>
      <Menu.Item onMouseEnter={() => setHover('2')} onMouseLeave={() => setHover('')} style={{ background: hover === '2' ? '#FFFCE2' : undefined }}>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('product-master-variants', File)}>
          <Row justify="space-between" style={{ color: '#DCB61D', width: '100%' }}>
            <Col>Product Master Variants</Col>
            <Col>
              <ImportOutlined />
            </Col>
          </Row>
        </Upload>
      </Menu.Item>
      <Menu.Item onMouseEnter={() => setHover('3')} onMouseLeave={() => setHover('')} style={{ background: hover === '3' ? '#EDFFF2' : undefined }}>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('released-products', File)}>
          <Row justify="space-between" style={{ color: '#008921', width: '100%' }}>
            <Col>Released Products</Col>
            <Col>
              <ImportOutlined />
            </Col>
          </Row>
        </Upload>
      </Menu.Item>
      <Menu.Item onMouseEnter={() => setHover('4')} onMouseLeave={() => setHover('')} style={{ background: hover === '4' ? '#FFF6F6' : undefined }}>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('released-product-variants', File)}>
          <Row justify="space-between" style={{ color: '#9E0000', width: '100%' }}>
            <Col>Released Product Variant</Col>
            <Col>
              <ImportOutlined />
            </Col>
          </Row>
        </Upload>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
      <Button>Import</Button>
    </Dropdown>
  );
};
