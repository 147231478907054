/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { SearchOutlined } from '@ant-design/icons';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { Input, InputRef, Table } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { useFormikContext } from 'formik';
import { CardPropsModel } from 'models/CardPropsModel';
import { ReleasedProduct } from 'models/ReleasedProduct';
import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { VList } from 'virtual-table-ant-design';
import { ReleaseProductModel } from './ViewReleaseProductStep';

interface DataType {
  key: string;
  ownerId: string;
  conditionId: number;
  dispositionId: string;
}

type DataIndex = keyof DataType;

interface Props {
  releasedProductData?: ReleasedProduct;
  fetching?: boolean;
  counter?: string;
}

export const ReleaseVariant: React.FC<Props> = ({ releasedProductData, fetching, counter }: Props) => {
  const searchInput = useRef<InputRef>(null);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>, setSelectedKeys: (selectedKeys: React.Key[]) => void, selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    setSelectedKeys(e.target.value ? [e.target.value] : []);
    confirm({ closeDropdown: false });
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${dataIndex}`}
          value={(selectedKeys as string[])[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e, setSelectedKeys, selectedKeys as string[], confirm)}
          // onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId',
      defaultSortOrder: 'ascend',
      width: '30%',
      sorter: (a, b): number => {
        if (a.ownerId < b.ownerId) {
          return -1;
        }
        if (a.ownerId > b.ownerId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('ownerId')
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId',
      defaultSortOrder: 'ascend',
      width: '20%',
      sorter: (a, b): number => {
        if (a.conditionId < b.conditionId) {
          return -1;
        }
        if (a.conditionId > b.conditionId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('conditionId')
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      defaultSortOrder: 'ascend',
      key: 'dispositionId',
      ...getColumnSearchProps('dispositionId'),
      sorter: (a, b): number => {
        if (a.dispositionId < b.dispositionId) {
          return -1;
        }
        if (a.dispositionId > b.dispositionId) {
          return 1;
        }

        return 0;
      },
      sortDirections: ['descend', 'ascend']
    }
  ];

  const { productMasterId } = useParams();

  const { values, setValues, isSubmitting } = useFormikContext<ReleaseProductModel>();

  const { data: productMasterData, isLoading } = useGetProductMasterQuery(productMasterId ?? skipToken);

  const productMasterVariants = productMasterData?.productMasterVariants ?? [];

  const tableData = productMasterVariants.map(({ productOwnerId, productConditionId, productDispositionId }) => ({
    ownerId: productOwnerId,
    conditionId: productConditionId,
    dispositionId: productDispositionId
  }));

  const handleSelectRow = (record: CardPropsModel | undefined, selected: boolean, selectedRows: CardPropsModel[]): void => {
    setValues((prev) => ({
      ...prev,
      variants: [...selectedRows]
    }));
  };

  const existingVariantsCombinations = releasedProductData?.releasedProductVariants?.map((variant) => `${variant.ownerId} - ${variant.conditionId} - ${variant.dispositionId}`);
  // const filteredData = tableData.filter(({ ownerId, conditionId, dispositionId }) => !existingVariantsCombinations?.includes(`${ownerId} - ${conditionId} - ${dispositionId}`));

  return (
    <>
      <Table
        loading={{
          indicator: <BlockLoader direction="loader loader--slideUp" loadingMessage={counter} />,
          spinning: isSubmitting || fetching
        }}
        size="small"
        columns={columns}
        bordered={true}
        pagination={false}
        scroll={{ y: 625 }}
        components={VList({ height: 625 })}
        dataSource={tableData.filter(({ ownerId, conditionId, dispositionId }) => !existingVariantsCombinations?.includes(`${ownerId} - ${conditionId} - ${dispositionId}`)) as any[]}
        rowSelection={{
          type: 'checkbox',
          onSelect: (record, selected, selectedRows): void => {
            const filteredRows = selectedRows.filter((row) => row !== undefined);

            handleSelectRow(record, selected, filteredRows);
          },
          selectedRowKeys: values.variants.map(({ ownerId, conditionId, dispositionId }) => `${ownerId} - ${conditionId} - ${dispositionId}`),
          checkStrictly: true,
          onSelectAll: (selected, selectedRows): void => handleSelectRow(undefined, selected, selectedRows)
        }}
        rowKey={({ ownerId, conditionId, dispositionId }): string => `${ownerId} - ${conditionId} - ${dispositionId}`}
      />
    </>
  );
};
