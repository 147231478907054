import { Card, Col, Row, Spin } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { CategorySelectionInput } from 'components/UI/FormItems/CategorySelectionInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { SizedLoader } from 'components/atoms/SizedLoader';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetLegalEntitiesQuery } from 'redux/services/maggieCadabby/maggieCadabbyApi';
import { RootState } from 'redux/store';

export const ReleaseProductStep: React.FC = () => {
  const { maggieCadabbyParams } = useSelector((state: RootState) => state);
  const { data, isLoading } = useGetLegalEntitiesQuery(maggieCadabbyParams);

  const legalEntityOptions = data?.data.map((legalEntity) => ({ label: legalEntity.dataAreaId, value: legalEntity.dataAreaId }));

  return (
    <>
      <Col style={{ padding: 10, background: '#18a79984' }}>
        <Row justify="space-between" align="middle">
          <AntPageTitle text="Released Product" />
        </Row>
      </Col>
      <Card bordered={false}>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ marginBottom: 8 }}>
            <Spin spinning={isLoading} indicator={<SizedLoader medium />}>
              <SelectInput options={legalEntityOptions} label="Legal Entity" fieldName="legalEntity" />
            </Spin>
          </Col>
          <Col span={12} style={{ marginBottom: 8 }}>
            <SelectInput fieldName="itemModelGroup" label="Item Model Group" options={[{ label: 'Stock', value: 'STOCK' }]} defaultValue={'STOCK'} />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ marginBottom: 8 }}>
            <SelectInput fieldName="unitOfMeasure" label="Unit of Measure" options={[{ label: 'EA', value: 'EA' }]} defaultValue={'EA'} />
          </Col>
          <Col span={12} style={{ marginBottom: 8 }}>
            <CategorySelectionInput fieldName="categoryId" label="Category" />
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12} style={{ marginBottom: 8 }}>
            <SelectInput fieldName="productDimensionGroupName" label="Product Dimension Group" options={[{ label: 'OCD', value: 'OCD' }]} defaultValue={'OCD'} />
          </Col>
        </Row>
      </Card>
    </>
  );
};
