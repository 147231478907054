import { Card, Col, Form, Input, Row } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { TextInput } from 'components/UI/FormItems';
import { FormLabel } from 'components/UI/FormItems/FormLabel';
import { ManufacturerSelectInput } from 'components/UI/FormItems/ManufacturerInput';
import { SelectInput } from 'components/UI/FormItems/SelectInput';
import { TrackingDimensionInput } from 'components/UI/FormItems/TrackingDimensionInput';
import { useField, useFormikContext } from 'formik';
import { CreateProductMastersModel } from 'models/ProductMaster';
import React from 'react';

export const ProductDetailsStep: React.FC = () => {
  const { values, setValues, touched } = useFormikContext<CreateProductMastersModel>();
  const [_, { error }, { setTouched }] = useField('productNumber');

  return (
    <>
      <Col style={{ padding: 10, background: '#FFEAC1' }}>
        <Row justify="space-between" align="middle">
          <AntPageTitle text="Product Details" />
        </Row>
      </Col>
      <Card>
        <Row gutter={[32, 16]}>
          <Col span={12}>
            <Form.Item
              validateStatus={touched.productNumber && error ? 'error' : 'success'}
              help={touched.productNumber && error ? error : undefined}
              labelAlign="left"
              label={<FormLabel label="Product Number" />}
              style={{ marginBottom: 0 }}
              labelCol={{ style: { padding: 0 } }}>
              <Input
                onFocus={() => setTouched(false)}
                onBlur={() => setTouched(true)}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    productNumber: e.target.value,
                    productName: e.target.value
                  }))
                }
                value={values.productNumber}
                style={{ borderRadius: 5 }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item labelAlign="left" label={<FormLabel label="Product Name" />} style={{ marginBottom: 0 }} labelCol={{ style: { padding: 0 } }}>
              <Input disabled value={values.productNumber} style={{ borderRadius: 5 }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <TextInput fieldName="productDescription" label="Product Description" />
          </Col>
          <Col span={12}>
            <SelectInput fieldName="storageDimensionGroupName" label="Storage Dimension Group" options={[{ label: 'WHSEMGT', value: 'WHSEMGT' }]} defaultValue={'WHSEMGT'} />
          </Col>
          <Col span={12}>
            <SelectInput
              fieldName="configurationTechnology"
              label="Configuration Technology"
              options={[{ label: 'Predifined Variant', value: 'PREDIFINED VARIANT' }]}
              defaultValue={'PREDIFINED VARIANT'}
            />
          </Col>
          <Col span={12}>
            <ManufacturerSelectInput fieldName="manufacturerId" label="Manufacturer" />
          </Col>
          <Col span={12}>
            <TrackingDimensionInput
              fieldName="trackingDimensionGroupName"
              label="Tracking Dimension Group"
              options={[
                { label: 'Serialized', value: 'SERIALIZED' },
                { label: 'Non-Serialized', value: 'NONSERIAL' }
              ]}
            />
          </Col>

          <Col span={12}>
            <SelectInput
              options={[
                { label: 'Serialized', value: 'SERIALIZED' },
                { label: 'Non-Serialized', value: 'NONSERIAL' }
              ]}
              fieldName="reservationHierarchy"
              label="Reservation Hierarchy"
              disabled
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};
