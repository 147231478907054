/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { QuestionCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputRef, Row, Table, Tooltip, message } from 'antd';
import { ColumnType, ColumnsType } from 'antd/lib/table';
import { FilterConfirmProps } from 'antd/lib/table/interface';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { BlockLoader } from 'components/atoms/BlockLoader';
import { FormikProvider, useFormik } from 'formik';
import { CardPropsModel } from 'models/CardPropsModel';
import { UpdatedErrorModel } from 'models/ErrorModel';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useCreateProductMasterVariantMutation, useGetProductMasterQuery } from 'redux/services/donaldGrump/donaldGrumpApi';
import { clearAllOcd, setConditionIds, setDispositionIds, setOwnerIds } from 'redux/slices/ocdSlice';
import { RootState } from 'redux/store';
import { VList } from 'virtual-table-ant-design';
import { ReleaseProductModel } from './ViewReleaseProductStep';

interface DataType {
  key: string;
  ownerId: string;
  conditionId: number;
  dispositionId: string;
}

type DataIndex = keyof DataType;

export const EditVariantsStep: React.FC = () => {
  const { productMasterId } = useParams();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const { data: productMasterData } = useGetProductMasterQuery(productMasterId as string);
  const [variants, setVariants] = useState<any[]>([]);
  const [releaseProductMessage, setReleasedProductMessage] = useState('');
  const { ownerIds, conditionIds, dispositionIds } = useSelector((state: RootState) => state.stagedOcd);
  const dispatch = useDispatch();

  const [createReleasedProductVariant] = useCreateProductMasterVariantMutation();

  const searchInput = useRef<InputRef>(null);

  const formik = useFormik<ReleaseProductModel>({
    enableReinitialize: true,
    validationSchema: undefined,
    initialValues: {
      variants: []
    },
    onSubmit: async (values) => {
      try {
        let i = 1;

        for (const variant of values.variants) {
          const productDescription = productMasterData?.productDescription;

          setReleasedProductMessage(`Creating product variant ${i} of ${formik.values.variants.length}`);
          await createReleasedProductVariant({
            productId: productMasterId as string,
            payload: { ...variant, ...{ productDescription, productName: productMasterId } }
          });
          i++;
        }
        formik.resetForm();
        message.success(`Product Variants were successfully created!`);
      } catch (error) {
        console.log(error);
        message.error((error as UpdatedErrorModel).data);
      }
    }
  });

  const handleSearch = (
    e: React.ChangeEvent<HTMLInputElement>,
    setSelectedKeys: (selectedKeys: React.Key[]) => void,
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    setSelectedKeys(e.target.value ? [e.target.value] : []);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    confirm({ closeDropdown: false });
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<DataType> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInput}
          placeholder={`Search by ${dataIndex}`}
          value={(selectedKeys as string[])[0]}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearch(e, setSelectedKeys, selectedKeys as string[], confirm, dataIndex)}
          // onPressEnter={(e) => }
          style={{ marginBottom: 8, display: 'block' }}
        />
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    }
  });

  const columns: ColumnsType<DataType> = [
    {
      title: 'Owner',
      dataIndex: 'ownerId',
      key: 'ownerId',
      defaultSortOrder: 'ascend',
      width: '30%',
      sorter: (a, b): number => {
        if (a.ownerId < b.ownerId) {
          return -1;
        }
        if (a.ownerId > b.ownerId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('ownerId')
    },
    {
      title: 'Condition',
      dataIndex: 'conditionId',
      key: 'conditionId',
      defaultSortOrder: 'ascend',
      width: '30%',
      sorter: (a, b): number => {
        if (a.conditionId < b.conditionId) {
          return -1;
        }
        if (a.conditionId > b.conditionId) {
          return 1;
        }

        return 0;
      },
      ...getColumnSearchProps('conditionId')
    },
    {
      title: 'Disposition',
      dataIndex: 'dispositionId',
      defaultSortOrder: 'ascend',
      key: 'dispositionId',
      ...getColumnSearchProps('dispositionId'),
      sorter: (a, b): number => {
        if (a.dispositionId < b.dispositionId) {
          return -1;
        }
        if (a.dispositionId > b.dispositionId) {
          return 1;
        }

        return 0;
      },
      sortDirections: ['descend', 'ascend']
    }
  ];

  useEffect(() => {
    if (productMasterData) {
      const { productMasterOwners, productMasterConditions, productMasterDispositions } = productMasterData;

      dispatch(setOwnerIds(productMasterOwners.map((owner) => (owner as { ownerId: string }).ownerId)));
      dispatch(setConditionIds(productMasterConditions.map((condition) => (condition as { conditionId: string }).conditionId)));
      dispatch(setDispositionIds(productMasterDispositions.map((disposition) => (disposition as { dispositionId: string }).dispositionId)));
    }
  }, [productMasterData]);

  useEffect(() => {
    setVariants([]);
    ownerIds.forEach((owner) =>
      conditionIds.forEach((condition) =>
        dispositionIds.forEach((disposition) =>
          setVariants((prev) => [
            ...prev,
            {
              ownerId: owner,
              conditionId: condition,
              dispositionId: disposition
            }
          ])
        )
      )
    );
  }, [ownerIds, conditionIds, dispositionIds]);

  useEffect(() => {
    return (): void => {
      dispatch(clearAllOcd());
    };
  }, []);

  const handleSelectRow = (record: CardPropsModel | undefined, selected: boolean, selectedRows: CardPropsModel[]): void => {
    formik.setValues((prev) => ({
      ...prev,
      variants: [...selectedRows]
    }));
  };

  const createdProductVariants = productMasterData?.productMasterVariants.map(
    ({ productOwnerId, productConditionId, productDispositionId }) => `${productOwnerId} - ${productConditionId} - ${productDispositionId}`
  );

  const tableData = variants.filter(({ ownerId, conditionId, dispositionId }) => !createdProductVariants?.includes(`${ownerId} - ${conditionId} - ${dispositionId}`));

  return (
    <FormikProvider value={formik}>
      <Col style={{ padding: 10, background: '#b0b2bc' }}>
        <Row justify="space-between" align="middle">
          <Row align="middle">
            <AntPageTitle text="Unassigned Product Variants" />
            <Tooltip title="Product Variants exist at two levels- the Product Master and the Released Product. This Product Master has already been released to a Legal Entity, and if you need to add more Released Product Variants to that Company, you do -not- need to add the variants here; simply create them on the Released Product tab and we'll automatically add them here. However, if you only want to create new Product Master Variants without adding them to Released Products, you may use this form to do so.">
              <QuestionCircleOutlined style={{ marginLeft: 8, color: 'rgba(0,0,0,.5)' }} />
            </Tooltip>
          </Row>
          <Button onClick={(): Promise<void> => formik.submitForm()} disabled={!formik.values.variants.length}>
            Create Product Variant
          </Button>
        </Row>
      </Col>
      <Card>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Table
              scroll={{ y: 500 }}
              components={VList({ height: 500 })}
              style={{ width: '100%' }}
              loading={{ indicator: <BlockLoader direction="loader loader--slideUp" loadingMessage={releaseProductMessage} />, spinning: formik.isSubmitting }}
              size="small"
              columns={columns}
              bordered={true}
              dataSource={tableData}
              pagination={false}
              rowSelection={{
                type: 'checkbox',
                onSelect: (record, selected, selectedRows): void => handleSelectRow(record, selected, selectedRows),
                onSelectAll: (selected, selectedRows): void => handleSelectRow(undefined, selected, selectedRows)
              }}
              rowKey={({ ownerId, conditionId, dispositionId }): string => `${ownerId} - ${conditionId} - ${dispositionId}`}
            />
          </Row>
        </Col>
      </Card>
    </FormikProvider>
  );
};
