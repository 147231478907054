import { Button, Col, Descriptions, Row, Typography } from 'antd';
import { AntPageTitle } from 'components/UI/AntPageTitle';
import { ProductMaster } from 'models/ProductMaster';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

interface Props {
  productMasterInfo?: ProductMaster;
}

export const ProductInformation: React.FC<Props> = ({ productMasterInfo }: Props) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { productMasterId } = useParams();

  const isVariantPage = pathname.includes('/released-product');

  return (
    <Row style={{ flexDirection: 'column' }}>
      <Row style={{ marginBottom: 15 }} justify="space-between">
        <Row align="middle">
          <Col style={{ marginRight: 10 }}>
            <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontWeight: 'bold', fontSize: 15 }}>Product Number: </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>{productMasterInfo?.productNumber || 'N/A'}</Typography.Text>
          </Col>
          <Col style={{ marginRight: 10 }}>
            <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontWeight: 'bold', fontSize: 15 }}>Product Name: </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>{productMasterInfo?.productName || 'N/A'}</Typography.Text>
          </Col>
          <Col>
            <Typography.Text style={{ color: 'rgb(47, 85, 150)', fontWeight: 'bold', fontSize: 15 }}>Manufacturer: </Typography.Text>
            <Typography.Text style={{ fontSize: 14 }}>{productMasterInfo?.manufacturerId || 'N/A'}</Typography.Text>
          </Col>
        </Row>
        <Col>
          <Col>
            <Button onClick={(): void => (!isVariantPage ? navigate('/') : navigate(`/edit-product-master/${encodeURIComponent(productMasterId ?? '')}`))} style={{ marginRight: 5 }}>
              Back
            </Button>
            {!isVariantPage && <Button onClick={(): void => navigate(`/edit-product-master/${encodeURIComponent(productMasterId ?? '')}/released-product`)}>Released Products</Button>}
          </Col>
        </Col>
      </Row>
      <Col style={{ padding: 10, background: 'rgb(206, 213, 242)' }}>
        <Row justify="space-between">
          <Col>
            <AntPageTitle text={pathname.includes('/released-product') ? 'Released Product Information' : 'Product Master Information'} />
          </Col>
        </Row>
      </Col>
      <Col style={{ background: 'white' }}>
        <Descriptions labelStyle={{ fontWeight: 'bold' }} column={10} layout="vertical" bordered size="small">
          <Descriptions.Item label="Product Description">{productMasterInfo?.productDescription}</Descriptions.Item>
          <Descriptions.Item label="Configuration Technology">{productMasterInfo?.variantConfigurationTechnology}</Descriptions.Item>
          <Descriptions.Item label="Storage Dimension Group">{productMasterInfo?.storageDimensionGroupName}</Descriptions.Item>
          <Descriptions.Item label="Tracking Dimension Group">{productMasterInfo?.trackingDimensionGroupName}</Descriptions.Item>
          <Descriptions.Item label="Product Type">{productMasterInfo?.productType}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
