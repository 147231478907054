import { Col, Row, Typography } from 'antd';
import React from 'react';
import './NoResultsStyle.css';

interface NoResultsErrorMessage {
  errorMessage: string;
}

const NoResults = ({ errorMessage }: NoResultsErrorMessage): JSX.Element => {
  return (
    <Row gutter={[0, 20]} style={{ width: '100%', paddingTop: '20px' }} justify="center">
      <Col span={24}>
        <div className="sea">
          <div className="circle-wrapper">
            <div className="bubble"></div>
            <div className="submarine-wrapper">
              <div className="submarine-body">
                <div className="window"></div>
                <div className="engine"></div>
                <div className="light"></div>
              </div>
              <div className="helix"></div>
              <div className="hat">
                <div className="leds-wrapper">
                  <div className="periscope"></div>
                  <div className="leds"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
      <Col>
        <Typography.Text>{errorMessage}</Typography.Text>
      </Col>
    </Row>
  );
};

export default NoResults;
