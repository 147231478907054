import { CheckOutlined, RightOutlined } from '@ant-design/icons';
import { Badge, Col, Space } from 'antd';
import { toRgb, userFormColors } from 'components/common/styles/colors';
import { useState } from 'react';
import Lottie from 'react-lottie-player';

interface Props {
  lottieFile: object;
  title: string;
  complete: boolean | null;
  speed?: number;
  required?: boolean;
}

export const LottieIcon = ({ lottieFile, complete, speed, title, required }: Props): JSX.Element => {
  const [playLottie, setPlayLottie] = useState(false);

  return (
    <Space align="center" onMouseEnter={(): void => setPlayLottie(true)} onMouseLeave={(): void => setPlayLottie(false)}>
      <Col style={{ display: 'flex', alignItems: 'center' }}>
        <Badge dot={required ? Boolean(!complete) : false} style={{ marginBottom: 5 }}>
          <Lottie speed={speed || 1} goTo={playLottie === false ? 0 : undefined} loop={false} animationData={lottieFile} play={playLottie} style={{ height: 25, width: 25, marginBottom: 2 }} />
        </Badge>
      </Col>
      <div>{title}</div>
      {complete ? <CheckOutlined style={{ color: toRgb(userFormColors.illuminatingEmerald) }} /> : null}
      <RightOutlined />
    </Space>
  );
};
