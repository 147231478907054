import { PlusOutlined } from '@ant-design/icons';
import { Card, Col, message, Row, Space, Spin, Typography, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useAuthToken } from 'hooks/useAuthToken';
import { CreateProductMasterVariantsModel } from 'models/CreateProductMasterVariantsModel';
import { CreateReleasedProductVariantsPayloadModel } from 'models/CreateReleasedProductVariantsPayloadModel';
import { ImportedProductMasterModel } from 'models/ImportedProductMasterModel';
import { ImportReleasedProductPayloadModel } from 'models/ImportReleasedProductPayloadModel';
import { InlineStylesModel } from 'models/InlineStylesModel';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setImportedProductMasters, setImportedProductMasterVariants, setImportedReleasedProducts, setImportedReleasedProductVariants } from 'redux/importedData/imported-data-slice';
import XLSX from 'xlsx';

const styles: InlineStylesModel = {
  card: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    border: 'solid 1px lightgray',
    cursor: 'pointer',
    height: '100%'
  },
  cardRow: {
    width: '100%',
    height: '37.5vh',
    marginBottom: '16px'
  },
  cardColumn: {
    maxWidth: 900,
    minWidth: 655
  },
  centerCardTitles: {
    textAlign: 'center'
  },
  mainContainer: {
    marginTop: '5vh'
  },
  spinnerText: {
    fontSize: 12,
    color: 'lightgray'
  },
  uploadContainer: {
    width: '100%',
    height: '100%'
  },
  uploadBox: {
    height: 110,
    width: 110,
    maxWidth: 110,
    maxHeight: 110
  },
  uploadActionsTop: {
    height: '50%',
    paddingBottom: 8
  },
  uploadActionsBottom: {
    height: '50%'
  },
  uploadRow: {
    marginBottom: 8
  }
};

export const ImportCards = (): JSX.Element => {
  const [parsedData, setParsedData] = useState<any>(null);
  const [selectedImport, setSelectedImport] = useState<string | null>(null);
  const [showSpinner, setShowSpiner] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useAuthToken();

  // Used to parse our given .xlsx file
  const parseData = (file: RcFile): boolean => {
    const reader = new FileReader();

    // Only .XLSX formatted files will have this type
    // Reject all non .XLSX files

    reader.onload = function (e): void {
      if (e.target !== null && e.target.result !== null) {
        const data = e.target.result;
        const workbook = XLSX.read(data, { type: 'array' });

        workbook.SheetNames.forEach(function (sheetName) {
          const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]) as unknown[];

          if (excelData.length) {
            setParsedData(excelData);
          } else {
            message.error(`There was an issue importing that file. Please ensure it's not emtpy & try again.`);
            setShowSpiner(false);
          }
        });
      }
    };

    reader.readAsArrayBuffer(file);

    return false;
  };

  // This function will be called before parsing of file
  // It sets the name of the import type to our local state
  // and then calls initiates the parse function
  const beforeUpload = (importTitle: string, File: RcFile): boolean => {
    if (File.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('You can only import files with the extension .xlsx');
      setShowSpiner(false);

      return false;
    }
    if (selectedImport === null) {
      setSelectedImport(importTitle);
    }

    if (showSpinner !== true) {
      setShowSpiner(true);
    }
    parseData(File);

    return false;
  };

  // Once both the import type has been set
  // and the file has been parsed we make our API call
  // if a file isnt parsed successfully, our parsedData local state remains null
  // and we skip this use effect

  useEffect(() => {
    if (parsedData !== null && selectedImport !== null) {
      switch (selectedImport) {
        case 'product-masters': {
          const importedProductMasters = parsedData as ImportedProductMasterModel[];

          dispatch(setImportedProductMasters(importedProductMasters));
          navigate(`/import/product-masters`);
          break;
        }

        case 'product-master-variants': {
          const importedProductMasterVariants = parsedData as CreateProductMasterVariantsModel[];

          dispatch(setImportedProductMasterVariants(importedProductMasterVariants));
          navigate(`/import/product-masters-variants`);
          break;
        }

        case 'released-products': {
          const importedReleasedProducts = parsedData as ImportReleasedProductPayloadModel[];

          dispatch(setImportedReleasedProducts(importedReleasedProducts));
          navigate(`/import/released-products`);
          break;
        }

        case 'released-product-variants': {
          const importedReleasedProductVariants = parsedData as CreateReleasedProductVariantsPayloadModel[];

          dispatch(setImportedReleasedProductVariants(importedReleasedProductVariants));
          navigate(`/import/released-product-variants`);
          break;
        }
      }
    }
  }, [parsedData, selectedImport, token]);

  // Displayed when a card is being parsed. Important for large files
  const spinnerJSX = (
    <div>
      <Row justify="center" align="middle">
        <Spin />
      </Row>
      <Row justify="center" align="middle">
        <p style={styles.spinnerText}>Reading your file </p>
      </Row>
    </div>
  );

  const uploadBoxJSX = (
    <Row justify="center" style={styles.uploadRow}>
      <Col className="upload-box" style={styles.uploadBox} span={8}>
        <Row justify="center" align="bottom" style={styles.uploadActionsTop}>
          <PlusOutlined />
        </Row>
        <Row justify="center" align="top" style={styles.uploadActionsBottom}>
          Upload
        </Row>
      </Col>
    </Row>
  );

  // Grid with all four cards
  const cardsJSX = (
    <>
      <Space>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('product-masters', File)}>
          <Card style={styles.card}>
            <Typography.Title level={4} style={styles.centerCardTitles}>
              Import Product Masters
            </Typography.Title>
            {uploadBoxJSX}
            <Typography.Paragraph style={styles.centerCardTitles}>Drag and Drop Files to Import</Typography.Paragraph>
            {selectedImport === 'product-masters' && showSpinner ? spinnerJSX : null}
          </Card>
        </Upload>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('product-master-variants', File)}>
          <Card style={styles.card}>
            <Typography.Title level={4} style={styles.centerCardTitles}>
              Import Product Master Variants
            </Typography.Title>
            {uploadBoxJSX}
            <Typography.Paragraph style={styles.centerCardTitles}>Drag and Drop Files to Import</Typography.Paragraph>
            {selectedImport === 'product-master-variants' && showSpinner ? spinnerJSX : null}
          </Card>
        </Upload>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('released-products', File)}>
          <Card style={styles.card}>
            <Typography.Title level={4} style={styles.centerCardTitles}>
              Import Released Products
            </Typography.Title>
            {uploadBoxJSX}
            <Typography.Paragraph style={styles.centerCardTitles}>Drag and Drop Files to Import</Typography.Paragraph>
            {selectedImport === 'released-products' && showSpinner ? spinnerJSX : null}
          </Card>
        </Upload>
        <Upload accept=".xlsx" showUploadList={false} beforeUpload={(File): boolean => beforeUpload('released-product-variants', File)}>
          <Card style={styles.card}>
            <Typography.Title level={4} style={styles.centerCardTitles}>
              Import Released Product Variants
            </Typography.Title>
            {uploadBoxJSX}
            <Typography.Paragraph style={styles.centerCardTitles}>Drag and Drop Files to Import</Typography.Paragraph>
            {selectedImport === 'released-product-variants' && showSpinner ? spinnerJSX : null}
          </Card>
        </Upload>
      </Space>
    </>
  );

  return cardsJSX;
};
