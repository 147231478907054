import './common/styles/antd.customize.less';
import './common/styles/global.less';

import * as serviceWorker from './serviceWorker';

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './app/App';
import Auth0ProviderWithHistory from './redux/auth0/Auth0-Provider';
import store from './redux/store';

const container = document.getElementById('root') as HTMLElement;

const root = createRoot(container);

root.render(
  <Provider store={store}>
    <Router>
      <Auth0ProviderWithHistory>
        <App />
      </Auth0ProviderWithHistory>
    </Router>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
