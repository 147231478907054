import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ManufacturerParams {
  offset: number;
  nameContains: string | undefined;
}

const initialState: ManufacturerParams = {
  offset: 0,
  nameContains: undefined
};

export const manufacturerParams = createSlice({
  name: 'manufacturerParams',
  initialState,
  reducers: {
    incrementOffset: (state): void => {
      state.offset += 25;
    },
    setManufacturerNameContains: (state, { payload }: PayloadAction<ManufacturerParams['nameContains']>): void => {
      state.nameContains = payload;
    }
  }
});

export const { incrementOffset, setManufacturerNameContains } = manufacturerParams.actions;
