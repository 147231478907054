import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { appSlice } from './app/app-slice';
import { importDataSlice } from './importedData/imported-data-slice';
import { productMastersSlice } from './productMasters/product-masters-slice';
import { productsSlice } from './products/products-slice';
import { releasedProductsSlice } from './releasedProducts/released-products-slice';
import { animalsApi, animalsConditionsParams, animalsDispositionsParams, animalsOwnersParams } from './services/animals';
import { cookieMonsterApi, userParams } from './services/cookieMonster';
import { donaldGrumpApi, donaldGrumpProductMastersParams, donaldGrumpReleasedProductsParams } from './services/donaldGrump';
import { manufacturerParams } from './services/donaldGrump/manufacturerParams';
import { freddyCadabbyApi, freddyCadabbyParams } from './services/freddyCadabby';
import { maggieCadabbyApi, maggieCadabbyParams } from './services/maggieCadabby';
import { createProductMasterStateSlice } from './slices/createProductMasterStateSlice';
import { stagedOcdSlice } from './slices/ocdSlice';
import { variantsSlice } from './variants/variants-slice';

const store = configureStore({
  reducer: {
    [appSlice.name]: appSlice.reducer,
    [productMastersSlice.name]: productMastersSlice.reducer,
    [productsSlice.name]: productsSlice.reducer,
    [releasedProductsSlice.name]: releasedProductsSlice.reducer,
    [importDataSlice.name]: importDataSlice.reducer,
    [variantsSlice.name]: variantsSlice.reducer,
    [stagedOcdSlice.name]: stagedOcdSlice.reducer,
    [createProductMasterStateSlice.name]: createProductMasterStateSlice.reducer,

    // FREDDY CADABBY
    [freddyCadabbyApi.reducerPath]: freddyCadabbyApi.reducer,
    [freddyCadabbyParams.name]: freddyCadabbyParams.reducer,

    // DONALD GRUMP
    [donaldGrumpApi.reducerPath]: donaldGrumpApi.reducer,
    [donaldGrumpProductMastersParams.name]: donaldGrumpProductMastersParams.reducer,
    [donaldGrumpReleasedProductsParams.name]: donaldGrumpReleasedProductsParams.reducer,
    [manufacturerParams.name]: manufacturerParams.reducer,

    // MAGGIE CADABBY

    [maggieCadabbyApi.reducerPath]: maggieCadabbyApi.reducer,
    [maggieCadabbyParams.name]: maggieCadabbyParams.reducer,

    // ANIMLS

    [animalsApi.reducerPath]: animalsApi.reducer,
    [animalsConditionsParams.name]: animalsConditionsParams.reducer,
    [animalsDispositionsParams.name]: animalsDispositionsParams.reducer,
    [animalsOwnersParams.name]: animalsOwnersParams.reducer,

    // COOKIE MONSTER

    [cookieMonsterApi.reducerPath]: cookieMonsterApi.reducer,
    [userParams.name]: userParams.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cookieMonsterApi.middleware).concat(maggieCadabbyApi.middleware).concat(freddyCadabbyApi.middleware).concat(animalsApi.middleware).concat(donaldGrumpApi.middleware)
});

// if (process.env.NODE_ENV === 'development' && module.hot) {
//   module.hot.accept('./rootReducer', () => {
//     // eslint-disable-next-line @typescript-eslint/no-var-requires
//     const newRootReducer = require('./rootReducer').default;

//     store.replaceReducer(newRootReducer);
//   });
// }

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export type ThunkAppDispatch = ThunkDispatch<RootState, void, AnyAction>;

export default store;
