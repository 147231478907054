import * as ExcelJs from 'exceljs';
import * as FileSaver from 'file-saver';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { productMasterColumns, productMasterVariantsColumns, releasedProductsColumns, releasedProductVariantsColumns } from 'common/globalVariables/ExportedDataColumns';

import { CreateProductMasterVariantsModel } from 'models/CreateProductMasterVariantsModel';
import { CreateReleasedProductPayload } from 'models/CreateReleasedProductPayload';
import { CreateReleasedProductVariantsPayloadModel } from 'models/CreateReleasedProductVariantsPayloadModel';
import { ErrorModel } from 'models/ErrorModel';
import { ImportedProductMasterModel } from 'models/ImportedProductMasterModel';
import { ImportProductMasterModel } from 'models/ImportProductMasterModel';
import { ImportReleasedProductPayloadModel } from 'models/ImportReleasedProductPayloadModel';
import { RootState } from 'redux/store';
import { ProductMasterService } from 'services/ProductMasterService';
import { ProductService } from 'services/ProductsService';
import { ReleasedProductService } from 'services/ReleasedProductService';

/* ****************** INTERFACES ****************** */
interface ImportedDataModel {
  error: ErrorModel | null;
  finishedImportCallsCounter: number;
  importedProductMasters: ImportedProductMasterModel[] | null;
  importedProductMasterVariants: CreateProductMasterVariantsModel[] | null;
  importedReleasedProducts: ImportReleasedProductPayloadModel[] | null;
  importedReleasedProductVariants: CreateReleasedProductVariantsPayloadModel[] | null;
  loading: boolean;
  successfulImports: { productNumber: string; message: string }[];
  errorImports: { productNumber: string; message: string }[];
}

const initialState: ImportedDataModel = {
  error: null,
  errorImports: [],
  finishedImportCallsCounter: 0,
  importedProductMasters: null,
  importedProductMasterVariants: null,
  importedReleasedProductVariants: null,
  importedReleasedProducts: null,
  loading: false,
  successfulImports: []
};

interface ProductVariantsWithMessage extends CreateProductMasterVariantsModel {
  message: string;
}

interface ReleasedProductWithMessage extends CreateReleasedProductPayload {
  message: string;
}

interface ReleasedProductVariantWithMessage extends CreateReleasedProductVariantsPayloadModel {
  message: string;
}

/* ****************** ASYNC THUNKS ****************** */
export const asyncImportProductMaster = createAsyncThunk<
  { productNumber: string; message: string },
  { importedProductMaster: ImportedProductMasterModel; token: string },
  {
    state: RootState;
  }
>('productMasters/asyncImportProductMaster', async (params, thunkOptions) => {
  const { rejectWithValue } = thunkOptions;

  const rejectionPayload = {
    productNumber: params.importedProductMaster.productNumber,
    productDescription: params.importedProductMaster.productDescription,
    storageDimensionGroupName: params.importedProductMaster.storageDimensionGroup,
    productDimensionGroupName: params.importedProductMaster.productDimensionGroup,
    trackingDimensionGroupName: params.importedProductMaster.trackingDimensionGroup,
    manufacturerId: params.importedProductMaster.manufacturer,
    categoryId: params.importedProductMaster.category
  };

  if (params.importedProductMaster.productNumber === undefined) {
    const rejectPayload = { ...rejectionPayload, ...{ productNumber: 'N/A' } };

    return rejectWithValue({ ...rejectPayload, ...{ message: 'Missing Product Number' } });
  }

  if (params.importedProductMaster.productDescription === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Product Description' } });
  }

  if (params.importedProductMaster.storageDimensionGroup === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Storage Dimension Group' } });
  }

  if (params.importedProductMaster.productDimensionGroup === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Product Dimension Group' } });
  }

  if (params.importedProductMaster.trackingDimensionGroup === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Tracking Dimension Group' } });
  }

  if (params.importedProductMaster.manufacturer === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Manufacturer' } });
  }
  if (params.importedProductMaster.category === undefined) {
    return rejectWithValue({ ...rejectionPayload, ...{ message: 'Missing Category' } });
  }

  const productDescriptionTrimmed = params.importedProductMaster.productDescription.trim();
  const cutoff = productDescriptionTrimmed.length < 60 ? productDescriptionTrimmed.length : 60;
  const productNameCutoff = params.importedProductMaster.productDescription.substring(0, cutoff);

  const createPayload: ImportProductMasterModel = {
    productNumber: params.importedProductMaster.productNumber.trim(),
    productName: productNameCutoff,
    productDescription: params.importedProductMaster.productDescription.trim(),
    storageDimensionGroupName: params.importedProductMaster.storageDimensionGroup.trim(),
    productDimensionGroupName: params.importedProductMaster.productDimensionGroup.trim(),
    trackingDimensionGroupName: params.importedProductMaster.trackingDimensionGroup.trim(),
    manufacturerId: params.importedProductMaster.manufacturer.trim(),
    categoryId: params.importedProductMaster.category.trim()
  };

  const responseDTO = await ProductService.createImportedProduct(params.token, createPayload);

  if (responseDTO.error !== null) {
    return rejectWithValue({ ...createPayload, ...{ message: responseDTO.error.errorMessage } });
  }

  return { productNumber: params.importedProductMaster.productNumber, message: 'Success' };
});

export const asyncImportProductMasterVariant = createAsyncThunk<
  ProductVariantsWithMessage,
  { importedProductMasterVariant: CreateProductMasterVariantsModel | null; token: string },
  {
    state: RootState;
  }
>('productMasters/asyncImportProductMasterVariants', async (params, thunkOptions) => {
  const { rejectWithValue } = thunkOptions;

  if (params.importedProductMasterVariant === null) {
    return rejectWithValue('Something');
  }

  if (params.importedProductMasterVariant.productNumber === undefined) {
    const rejectPayload = { ...params.importedProductMasterVariant, ...{ productNumber: 'N/A' } };

    return rejectWithValue({ ...rejectPayload, ...{ message: 'Missing Product Number' } });
  }

  if (params.importedProductMasterVariant.ownerId === undefined) {
    return rejectWithValue({ ...params.importedProductMasterVariant, ...{ message: 'Missing OwnerId' } });
  }

  if (params.importedProductMasterVariant.conditionId === undefined) {
    return rejectWithValue({ ...params.importedProductMasterVariant, ...{ message: 'Missing ConditionId' } });
  }

  if (params.importedProductMasterVariant.dispositionId === undefined) {
    return rejectWithValue({ ...params.importedProductMasterVariant, ...{ message: 'Missing DispositionId' } });
  }

  const createPayload: CreateProductMasterVariantsModel = {
    productNumber: params.importedProductMasterVariant.productNumber.trim(),
    productName: params.importedProductMasterVariant.productNumber.trim(),
    ownerId: params.importedProductMasterVariant.ownerId.trim(),
    conditionId: params.importedProductMasterVariant.conditionId.trim(),
    dispositionId: params.importedProductMasterVariant.dispositionId.trim()
  };

  const responseDTO = await ProductMasterService.createProductMasterVariants(params.token, createPayload);

  if (responseDTO.error !== null) {
    return rejectWithValue({ ...params.importedProductMasterVariant, ...{ message: responseDTO.error.errorMessage } });
  }

  return { ...params.importedProductMasterVariant, ...{ message: 'success' } };
});

export const asyncImportReleasedProduct = createAsyncThunk<
  ReleasedProductWithMessage,
  { importedReleasedProduct: ImportReleasedProductPayloadModel | null; token: string },
  {
    state: RootState;
  }
>('productMasters/asyncImportReleasedProducts', async (params, thunkOptions) => {
  const { rejectWithValue } = thunkOptions;

  if (params.importedReleasedProduct === null) {
    return rejectWithValue('something');
  }

  if (params.importedReleasedProduct.productNumber === undefined) {
    const rejectPayload = { ...params.importedReleasedProduct, ...{ productNumber: 'N/A' } };

    return rejectWithValue({ ...rejectPayload, ...{ message: 'Missing Product Number' } });
  }
  if (params.importedReleasedProduct.reservationHierarchy === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Reservation Hierarchy' } });
  }

  if (params.importedReleasedProduct.itemModelGroup === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Item Model Group' } });
  }

  if (params.importedReleasedProduct.productGroupId === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Product Group Id' } });
  }

  if (params.importedReleasedProduct.unitOfMeasure === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Unit of Measure' } });
  }

  if (params.importedReleasedProduct.legalEntity === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Legal Entity' } });
  }

  if (params.importedReleasedProduct.productDimensionGroup === undefined) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: 'Missing Product Dimension Group' } });
  }

  const createPayload: CreateReleasedProductPayload = {
    productNumber: params.importedReleasedProduct.productNumber.trim(),
    reservationHierarchy: params.importedReleasedProduct.reservationHierarchy.trim(),
    itemModelGroup: params.importedReleasedProduct.itemModelGroup.trim(),
    productGroupId: params.importedReleasedProduct.productGroupId.trim(),
    unitOfMeasure: params.importedReleasedProduct.unitOfMeasure.trim(),
    productDimensionGroupName: params.importedReleasedProduct.productDimensionGroup?.trim()
  };

  const responseDTO = await ReleasedProductService.createReleasedProducts(params.token, params.importedReleasedProduct.legalEntity as string, createPayload);

  if (responseDTO.error !== null) {
    return rejectWithValue({ ...params.importedReleasedProduct, ...{ message: responseDTO.error.errorMessage } });
  }

  return { ...params.importedReleasedProduct, ...{ message: 'Success' } };
});

export const asyncImportReleasedProductVariant = createAsyncThunk<
  ReleasedProductVariantWithMessage,
  { importedReleasedProductVariant: CreateReleasedProductVariantsPayloadModel | null; token: string },
  {
    state: RootState;
  }
>('productMasters/asyncImportReleasedProductVariants', async (params, thunkOptions) => {
  const { rejectWithValue } = thunkOptions;

  if (params.importedReleasedProductVariant === null) {
    return rejectWithValue('Something');
  }

  if (params.importedReleasedProductVariant.productNumber === undefined) {
    const rejectPayload = { ...params.importedReleasedProductVariant, ...{ productNumber: 'N/A' } };

    return rejectWithValue({ ...rejectPayload, ...{ message: 'Missing Product Number' } });
  }

  if (params.importedReleasedProductVariant.ownerId === undefined) {
    return rejectWithValue({ ...params.importedReleasedProductVariant, ...{ message: 'Missing OwnerId' } });
  }

  if (params.importedReleasedProductVariant.conditionId === undefined) {
    return rejectWithValue({ ...params.importedReleasedProductVariant, ...{ message: 'Missing ConditionId' } });
  }

  if (params.importedReleasedProductVariant.dispositionId === undefined) {
    return rejectWithValue({ ...params.importedReleasedProductVariant, ...{ message: 'Missing DispositionId' } });
  }

  if (params.importedReleasedProductVariant.legalEntity === undefined) {
    return rejectWithValue({ ...params.importedReleasedProductVariant, ...{ message: 'Missing Legal Entity' } });
  }

  const createPayload: CreateReleasedProductVariantsPayloadModel = {
    conditionId: params.importedReleasedProductVariant.conditionId.trim(),
    dispositionId: params.importedReleasedProductVariant.dispositionId.trim(),
    ownerId: params.importedReleasedProductVariant.ownerId.trim(),
    productNumber: params.importedReleasedProductVariant.productNumber?.trim(),
    legalEntity: params.importedReleasedProductVariant.legalEntity?.trim()
  };

  const responseDTO = await ReleasedProductService.createReleasedProductsVariants(
    params.token,
    params.importedReleasedProductVariant.legalEntity as string,
    params.importedReleasedProductVariant.productNumber as string,
    createPayload
  );

  if (responseDTO.error !== null) {
    return rejectWithValue({ ...params.importedReleasedProductVariant, ...{ message: responseDTO.error.errorMessage } });
  }

  return { ...params.importedReleasedProductVariant, ...{ message: 'Success' } };
});

/* ****************** SLICE ****************** */
export const importDataSlice = createSlice({
  name: 'importData',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(asyncImportProductMaster.fulfilled, (state, action) => {
      state.successfulImports.push(action.payload);
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportProductMaster.rejected, (state, action) => {
      state.errorImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportProductMasterVariant.fulfilled, (state, action) => {
      state.successfulImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportProductMasterVariant.rejected, (state, action) => {
      state.errorImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportReleasedProduct.fulfilled, (state, action) => {
      state.successfulImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportReleasedProduct.rejected, (state, action) => {
      state.errorImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportReleasedProductVariant.fulfilled, (state, action) => {
      state.successfulImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });

    builder.addCase(asyncImportReleasedProductVariant.rejected, (state, action) => {
      state.errorImports.push(action.payload as { productNumber: string; message: string });
      state.finishedImportCallsCounter = state.finishedImportCallsCounter + 1;
    });
  },
  reducers: {
    setImportedProductMasters(state, action: PayloadAction<ImportedProductMasterModel[] | null>): void {
      state.importedProductMasters = action.payload;
    },
    setImportedProductMasterVariants(state, action: PayloadAction<CreateProductMasterVariantsModel[] | null>): void {
      state.importedProductMasterVariants = action.payload;
    },
    setImportedReleasedProducts(state, action: PayloadAction<ImportReleasedProductPayloadModel[] | null>): void {
      state.importedReleasedProducts = action.payload;
    },
    setImportedReleasedProductVariants(state, action: PayloadAction<CreateReleasedProductVariantsPayloadModel[] | null>): void {
      state.importedReleasedProductVariants = action.payload;
    },

    setError(state, action: PayloadAction<ErrorModel | null>): void {
      state.error = action.payload;
    },
    clearImportSlice(state): void {
      state.successfulImports = [];
      state.errorImports = [];
      state.finishedImportCallsCounter = 0;
      state.importedProductMasters = null;
      state.importedProductMasterVariants = null;
      state.importedReleasedProducts = null;
      state.importedReleasedProductVariants = null;
    },
    exportToExcel(state, action: PayloadAction<string>): void {
      const workbook = new ExcelJs.Workbook();
      const worksheet = workbook.addWorksheet('Error Sheet');

      let columns: { header: string; key: string; width: number }[] | ExcelJs.Column[] = [];

      switch (action.payload) {
        case 'product-masters':
          columns = productMasterColumns;
          break;

        case 'product-master-variants':
          columns = productMasterVariantsColumns;
          break;

        case 'released-products':
          columns = releasedProductsColumns;
          break;

        case 'released-product-variants':
          columns = releasedProductVariantsColumns;
          break;
      }

      worksheet.columns = columns as ExcelJs.Column[];

      state.errorImports.forEach((row) => worksheet.addRow(row));

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        FileSaver.saveAs(blob, 'MDSI_ErrorReport.xlsx');
      });
    }
  }
});

export const { clearImportSlice, exportToExcel, setError, setImportedProductMasters, setImportedProductMasterVariants, setImportedReleasedProducts, setImportedReleasedProductVariants } =
  importDataSlice.actions;

export const importedDataReducer = importDataSlice.reducer;
